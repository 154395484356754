import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col, Card, Form, Button, ToastContainer, Toast } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle';
import { Link, useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import CmtService from '../../../services/cmt';
import TermServices from '../../../services/term';
import { log } from 'console';
import classServices from '../../../services/class';
import ProfileServices from '../../../services/profile';
import AccountServices from '../../../services/account';

interface ILecture {
  id: string;
  result: any;
  lastName: string;
}
interface IProfile {
  userId: string;
  lastName: string;
  result: any;

}

interface IFormInput {
  cmtId: number;
  lectureId: string;
  classCode: string;
  isOpen: boolean;
  isApproval: boolean;
}

interface ITerm {
  Id: number;
  TermCode: string;
  TermName: string;
}

interface ICourseMajor {
  Id: number;
  MajorId: number;
  CourseId: number;
  Major: IMajor;
  Course: ICourse;
}

interface IMajor {
  Id: number;
  MajorCode: string;
  MajorName: string;
}

interface ICourse {
  Id: number;
  CourseCode: string;
}

interface CMT {
  Id: number;
}

// Yup schema for validation
const schema = yup.object().shape({


  isOpen: yup.boolean(),
  isApproval: yup.boolean(),
});

const ClassForm: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  });

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [terms, setTerms] = useState<ITerm[]>([]);
  const [majors, setMajors] = useState<IMajor[]>([]);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [selectedTerm, setSelectedTerm] = useState<ITerm[]>([]);
  const [selectedMajor, setSelectedMajor] = useState<IMajor[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<ICourse[]>([]);
  const [selectedCourseMajor, setSelectedCourseMajor] = useState<ICourseMajor[]>([]);
  const navigate = useNavigate();
  const [lectures, setLectures] = useState<ILecture[]>([]);
  const [selectedLecture, setSelectedLecture] = useState<ILecture[]>([]);
  const [selectionError, setSelectionError] = useState({
    term: '',
    major: '',
    course: '',
    classCode: '',
    lectures: '',
  });
  const apiClass = new classServices();
  const apiCmt = new CmtService();
  const apiTerm = new TermServices();
  const apiLecture = new AccountServices(); // Your service to get lecture accounts
  const apiProfile = new ProfileServices(); // Your service to get profiles
  const fetchTerms = async () => {
    try {
      const data = await apiTerm.getAll();
      if (data && data.value) {
        const filteredTerms = data.value.filter((term: any) => term.TermCode && typeof term.TermCode === 'string');
        setTerms(filteredTerms);
      } else {
        setTerms([]);
        console.error("Failed to fetch terms or incorrect data format:", data);
      }
    } catch (error) {
      console.error("Error fetching terms:", error);
      setTerms([]);
    }
  };
  const fetchLectures = async () => {
    try {
      const response = await apiLecture.getAllAccountLecture();
      const lectureAccounts: ILecture[] = response.result;

      const profiles: IProfile[] = await Promise.all(
        lectureAccounts.map(lecture => apiProfile.getId(lecture.id))
      );
      const lecturesWithNames = lectureAccounts.map((lecture, index) => ({
        ...lecture,
        lastName: profiles[index].result.lastName + " " + profiles[index].result.firstName
      }));
      setLectures(lecturesWithNames);
    } catch (error) {
      console.error("Error fetching lectures:", error);
      setLectures([]);
    }
  };


  useEffect(() => {
    fetchTerms();
    fetchLectures();
  }, []);

  const handleTermSelect = async (selected: ITerm[]) => {
    setSelectedTerm(selected);
    // Clear majors and courses when a new term is selected
    setMajors([]);
    setCourses([]);
    setSelectedMajor([]); // Also clear selected major state
    setSelectedCourse([]); // Also clear selected course state

    if (selected.length) {
      try {
        const data = await apiCmt.getAllWithTerm(selected[0].Id);
        if (data && data.value) {
          const majorsData = data.value.map((item: any) => item.CourseMajor.Major);
          setMajors(majorsData);
          setSelectedCourseMajor(data.value);
        } else {
          console.error("Failed to fetch majors or incorrect data format:", data);
        }
      } catch (error) {
        console.error("Error fetching majors:", error);
      }
    }
  };

  const handleMajorSelect = async (selected: IMajor[]) => {
    setSelectedMajor(selected);
    setCourses([]);
    setSelectedCourse([]);

    if (selected.length > 0 && selected[0].Id !== undefined) {
      try {
        const data = await apiCmt.getAllWithMajor(selected[0].Id, selectedTerm[0].Id);
        if (data && data.value) {
          const filteredCourses = data.value.map((item: any) => item.CourseMajor.Course);
          setCourses(filteredCourses);
        } else {
          console.error("Failed to fetch courses or incorrect data format:", data);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    }
  };



  const onSubmit = async (data: IFormInput) => {
    let hasError = false;
    const newErrors = {
      term: '',
      major: '',
      course: '',
      classCode: '',
      lectures: '',
    };

    if (selectedTerm.length === 0) {
      newErrors.term = 'Please select a term.';
      hasError = true;
    }
    if (selectedMajor.length === 0) {
      newErrors.major = 'Please select a major.';
      hasError = true;
    }
    if (selectedCourse.length === 0) {
      newErrors.course = 'Please select a course.';
      hasError = true;
    }
    if (data.classCode == '') {
      newErrors.classCode = 'Please enter class code.';
      hasError = true;
    }
    if (selectedLecture.length === 0) {
      newErrors.lectures = 'Please select a lecturer.';
      hasError = true;
    }
    console.log("Selection Errors:", newErrors);

    try {


      setSelectionError(newErrors);

      const data1 = await apiCmt.getAllWithThreeParam(selectedTerm[0].Id, selectedMajor[0].Id, selectedCourse[0].Id);
      if (data1.value && data1.value.length > 0) {
        data.cmtId = data1.value[0].Id;
        data.lectureId = selectedLecture[0].id;
        await apiClass.create(data);
        navigate("/class")
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: 'Classes', path: '/class' },
          { label: 'Create Class', path: '/apps/classes/create', active: true },
        ]}
        title={'CREATE CLASS'}
      />
    
      <Row>
        <Col md={6} className="mx-auto">
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                
                <div className='mb-1' style={{ alignItems: 'baseline', fontSize: "12px" }}>
                  <span style={{ marginRight: '10px', fontWeight: "bold" }}>* NOTICE:</span>
                  <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                    Start by selecting the academic term. This choice sets the timeframe for your class and helps streamline the selection of majors relevant to the chosen term.
                  </p>
                  <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                    Next, select a major. Choosing the right major is crucial as it determines the specific courses available for your class within the selected academic term.
                  </p>
                  <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}> Click open when you want to open class or no.</p>
                </div>

                <Form.Group className="mb-3">
                  <Form.Label>Term</Form.Label>
                  <Typeahead
                    id="select-term"
                    labelKey="TermCode"
                    options={terms}
                    placeholder="Select a term..."
                    onChange={handleTermSelect}
                    selected={selectedTerm}
                    clearButton
                    inputProps={{ readOnly: true }}
                  />
                  {selectionError.term && <div className="text-danger">{selectionError.term}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Major</Form.Label>
                  <Typeahead
                    id="select-major"
                    labelKey="MajorCode"
                    options={majors}
                    placeholder="Select a major..."
                    onChange={handleMajorSelect}
                    selected={selectedMajor}
                    clearButton
                    inputProps={{ readOnly: true }}
                  />
                  {selectionError.major && <div className="text-danger">{selectionError.major}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Course</Form.Label>
                  <Typeahead
                    id="select-course"
                    labelKey="CourseCode"
                    options={courses}
                    placeholder="Select a course..."
                    onChange={(selected) => setSelectedCourse(selected)}
                    selected={selectedCourse}
                    clearButton
                    inputProps={{ readOnly: true }}
                  />
                  {selectionError.course && <div className="text-danger">{selectionError.course}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Lecture</Form.Label>
                  <Typeahead
                    id="select-lecture"
                    labelKey="lastName"  // Assuming you want to display the lecture's last name
                    options={lectures}
                    placeholder="Select a lecture..."
                    onChange={(selected) => setSelectedLecture(selected)}
                    selected={selectedLecture}
                    clearButton
                    inputProps={{ readOnly: true }}
                  />
                  {selectionError.lectures && <div className="text-danger">{selectionError.lectures}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Class Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Class Code"
                    {...register('classCode')}
                  />
                  {selectionError.classCode && <p className="text-danger">{selectionError.classCode}</p>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Open?"
                    {...register('isOpen')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Approval Needed?"
                    {...register('isApproval')}
                  />
                </Form.Group>
                <Row className="mt-2">
                  <Col className="text-center">
                    <Button
                      variant="success"
                      type="submit"
                      className="waves-effect waves-light m-1"
                    >
                      <i className="fe-check-circle me-1"></i> Create
                    </Button>
                    <Link to="/class" className="btn btn-danger waves-effect waves-light">
                      <i className="fe-x me-1"></i> Cancel
                    </Link>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClassForm;

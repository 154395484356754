import { Form, Dropdown } from 'react-bootstrap';

export function FormRender({ formData, setFormData }: any) {

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="topic">Topic</Form.Label>
        <Form.Control
          type="text"
          id="Topic"
          value={formData?.Topic || ""}
          onChange={e => setFormData({ ...formData, Topic: e.target.value })}
          placeholder="Enter Topic"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="questionContent">Question Content</Form.Label>
        <Form.Control
          type="text"
          id="QuestionContent"
          value={formData?.QuestionContent || ""}
          onChange={e => setFormData({ ...formData, QuestionContent: e.target.value })}
          placeholder="Enter Question Content"
        />
      </Form.Group>
    </div>
  );
}
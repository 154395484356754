import { useState, useEffect, useRef } from "react";
import Advanced from "../../../../tables/Advanced";
import { typeColumns } from "../data";
import Directoryervice from "../../../services/directory";
import { FormRender } from "./typeForm";
function TypeManagement() {
  const [type, settype] = useState([]);
  const [loading, setLoading] = useState(true);
 
  const typeService = new Directoryervice();


  const fetchtype = async () => {
    setLoading(true);
    const data = await typeService.getAll();
    if (typeof data === "string") {
      settype([]);
      console.error("Failed to fetch data:", data);
     
    } else {
      settype(data.value);
      
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchtype();
  }, []);

  const handleSubmit = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await typeService.create(data);
    console.log("Data submitted successfully:", response);
    fetchtype();
  };
 

  const handleUpdate = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await typeService.update(data.Id, data);
    console.log("Data submitted successfully:", response);
    fetchtype();
  };

  const handleDelete = async (id) => {
      try {
        const response = await typeService.delete(id);
        console.log("Data deleted successfully:", response);
      } catch (error) {
        console.error("Failed to delete data:", error);
      }
  };
  
  function formRender({ formData, setFormData } = {}) {
    return (
      <FormRender formData={formData} setFormData={setFormData} />
    )
  }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Advanced
          label="Directory Type"
          title="Directory Type MANAGEMENT"
          columns={typeColumns}
          data={type}
          link="/directory/create"
          formRender={formRender}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fetchData={fetchtype}
        />
      )}
    </div>
  );
}

export default TypeManagement;

import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import MajorService from '../../../services/major';
import CourseService from '../../../services/course';
import CourseMajorService from '../../../services/coursemajor';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../form.css";

const UpdateCourseMajorPage = ({ formData, setFormData }) => {

    const [majors, setMajors] = useState([]);
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [majorsData, coursesData] = await Promise.all([
                    new MajorService().getAll(),
                    new CourseService().getAll()
                ]);

                setMajors(majorsData.value);
                setCourses(coursesData.value);
            } catch (error) {
                console.error("Failed to fetch majors or courses", error);
                // Optionally, handle errors in UI here
            }
        };

        fetchData();
    }, []);

    const handleMajorChange = (selected) => {
        setFormData(formData => ({
            ...formData,
            MajorId: selected.length > 0 ? selected[0].Id : null
        }));
    };

    const handleCourseChange = (selected) => {
        setFormData(formData => ({
            ...formData,
            CourseId: selected.length > 0 ? selected[0].Id : null
        }));
        console.log("Selected:", selected);

    };
    console.log("Updated formData:", formData);
    return (
        <Row>
            <Col md={10} className="mx-auto">
                <Card>
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Select Major:</Form.Label>
                            <Col sm={9}>
                                <Typeahead
                                    id="major-selection"
                                    labelKey="MajorName"
                                    options={majors}
                                    selected={majors.filter(major => major.Id === formData?.MajorId)}  // Ensure formData has majorId not MajorId
                                    onChange={handleMajorChange}
                                    placeholder="Choose a major..."
                                    clearButton
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>Select Courses:</Form.Label>
                            <Col sm={9}>
                                <Typeahead
                                    id="course-selection"
                                    labelKey="CourseName"
                                    options={courses}
                                    selected={courses.filter(course => course.Id === formData?.CourseId)}
                                    onChange={handleCourseChange}
                                    placeholder="Select courses..."
                                    clearButton
                                />
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default UpdateCourseMajorPage;

import FetchClient from "../utils/axiosClient";
import { API } from "../utils/api";


class ContentServices {
    constructor() {
        this.AxiosClient = new FetchClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.CONTENT}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async get(id) {
        try {
            const term = await this.AxiosClient.get(`${API.CONTENT}?$filter=DirectoryClassId eq ${id}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.CONTENT}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.CONTENT}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }



    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.CONTENT}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.CONTENT}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default ContentServices;
import FetchClient from "../utils/axiosClient";
import { API } from "../utils/api";


class DepartmentServices {
    constructor() {
        this.AxiosClient = new FetchClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.DEPARTMENT}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllAdd() {
        try {
            const term = await this.AxiosClient.get(`${API.DEPARTMENT}?$filter=isDelete eq false`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.DEPARTMENT}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.DEPARTMENT}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.DEPARTMENT}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.DEPARTMENT}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default DepartmentServices;
import FetchClient from "../utils/axiosClient";
import { API } from "../utils/api";


class classServices {
    constructor() {
        this.AxiosClient = new FetchClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.CLASS}?$expand=Cmt($expand=CourseMajor($expand=Course,Major)&$expand=term)`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getCount() {
        try {
            const term = await this.AxiosClient.get(`${API.CLASS}/$count`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.CLASS}/${key}?$expand=Cmt($expand=CourseMajor($expand=Course,Major)&$expand=term)`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.CLASS}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.CLASS}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async Open(key) {
        try {
            const term = await this.AxiosClient.patch(`open-class/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
    
    async Close(key) {
        try {
            const term = await this.AxiosClient.patch(`close-class/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.CLASS}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default classServices;
import AxiosClient from "../utils/axiosClient";
import { API } from "../utils/api";


class EnrollmentServices {
    constructor() {
        this.AxiosClient = new AxiosClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.ENROLLMENT}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllStudennt(id) {
        try {
            const term = await this.AxiosClient.get(`${API.ENROLLMENT}?$filter=ClassId eq ${id}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
    async getCount() {
        try {
            const term = await this.AxiosClient.get(`${API.ENROLLMENT}/$count?$filter=IsAccept eq true`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllCount() {
        try {
            const term = await this.AxiosClient.get(`${API.ENROLLMENT}/$count`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
    async getAllCountToday(date) {
        try {
            const term = await this.AxiosClient.get(`${API.ENROLLMENT}/$count?$filter=CreatedDate eq ${date} `);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.ENROLLMENT}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.ENROLLMENT}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.ENROLLMENT}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.ENROLLMENT}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default EnrollmentServices;
import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle';
import MajorService from '../../../services/major';
import CategoryService from '../../../services/category';
import CourseService from '../../../services/course';
import { Link, useNavigate } from 'react-router-dom';
import "../form.css"

interface Major {
    Id: number;
    MajorCode: string;
    MajorName: string;
}

interface Course {
    Id: number;
    CourseName: string;
    CourseCode: string;
    CategoryId: number;
}

interface Category {
    Id: number;
    CategoryName: string;
}

interface CourseWithMajor {
    courseId: number;
    majorId: number;
}

const CreateCourseMajorPage: React.FC = () => {
    const [majors, setMajors] = useState<Major[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
    const [courses, setCourses] = useState<Course[]>([]);
    const [selectedMajorId, setSelectedMajorId] = useState<number | null>(null); // Thêm state cho selectedMajorId
    const majorService = new MajorService();
    const categoryService = new CategoryService();
    const courseService = new CourseService();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const majorsData = await majorService.getAll();
            const categoriesData = await categoryService.getAll();
            const coursesData = await courseService.getAll();
            setMajors(majorsData.value);
            setCategories(categoriesData.value);
            setCourses(coursesData.value);
            if (majorsData.value.length > 0) {
                setSelectedMajorId(majorsData.value[0].Id); // Chọn Major đầu tiên làm mặc định
            }
            if (coursesData.value.length > 0) {
                setSelectedCourses([coursesData.value[0].Id]);
            }
        };
        fetchData();
    }, []);

    const handleAddCourse = () => {
        setSelectedCourses(prev => [...prev, courses[0]?.Id]);
    };

    const removeCourse = (index: number) => {
        setSelectedCourses(prev => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const selectedMajors = selectedCourses.map(courseId => ({
                majorId: selectedMajorId, // Sử dụng selectedMajorId thay vì majors[0].Id
                courseId
            }));
            console.log("selected", selectedMajors);
            
            const majorCourseResponse = await fetch('https://crud.live:447/course-major/addlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(selectedMajors)
            });

            if (!majorCourseResponse.ok) {
                throw new Error(`HTTP error! status: ${majorCourseResponse.status}`);
            }
            navigate("/course");
            console.log("Courses and their majors have been successfully added!");
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Course', path: '/course' },
                    { label: 'Create Course', path: '/course/create', active: true },
                ]}
                title=" "
            />
            <Row>
                <Col md={6} className="mx-auto">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <div className="page-title-box">
                                    <h2 className="page-title">CREATE COURSE</h2>
                                </div>
                                <div className='mb-1' style={{ alignItems: 'baseline', fontSize: "12px" }}>
                                    <span style={{ marginRight: '10px', fontWeight: "bold" }}>* NOTICE:</span>
                                    <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                                        Start by selecting a major. This step is important as it defines the department or field to which the course belongs.
                                    </p>
                                    <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                                        Next, add courses related to the selected major. You can add multiple courses for the same major.
                                    </p>
                                    <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                                        Click on the "Add Course" button to include a new course. Ensure that the courses added are appropriate for the selected major.
                                    </p>
                                    <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                                        Once all desired courses are added, click "Create" to save the courses along with their associated major.
                                    </p>
                                    <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                                        Click "Back" if you want to return to the previous page without making changes.
                                    </p>
                                </div>
                                <Form.Group as={Row} className="mb-3 mt-3">
                                    <Form.Label column sm={3}>Select Major:</Form.Label>
                                    <Col sm={5}>
                                        <Form.Control
                                            as="select"
                                            value={selectedMajorId || ""}
                                            onChange={e => setSelectedMajorId(parseInt(e.target.value))}
                                        >
                                            {majors.map(major => (
                                                <option key={major.Id} value={major.Id}>{major.MajorName}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                {selectedCourses.map((courseId, index) => (
                                    
                                    <div key={index}>
                                        {index > 0 ?
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button variant="none" style={{ color: "black" }} onClick={() => removeCourse(index)}><i className="mdi mdi-trash-can"></i></Button>
                                        </div> : <div style={{height: "30px"}}></div>
                                        }
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={3}>Select Course:</Form.Label>
                                            <Col sm={5}>
                                                <Form.Control
                                                    as="select"
                                                    value={courseId}
                                                    onChange={e => {
                                                        const updatedCourses = selectedCourses.map((item, i) => i === index ? parseInt(e.target.value) : item);
                                                        setSelectedCourses(updatedCourses);
                                                    }}
                                                >
                                                    {courses.map(course => (
                                                        <option key={course.Id} value={course.Id}>{course.CourseName}</option>
                                                    ))}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                ))}
                                <Row className='mt-5'>
                                    <Col className="text-center">
                                        <Button variant="info" onClick={handleAddCourse}><i className="mdi mdi-plus" style={{ fontSize: "15px" }}></i> Add Course</Button>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col className="text-center">
                                        <Button variant="success" type="submit" className="m-2"><i className="fe-check-circle me-1" style={{ fontSize: "10px" }}></i> Create</Button>
                                        <Link to="/course" className="btn btn-danger">Back</Link>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CreateCourseMajorPage;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import classServices from "../../admin/services/class";
import PageTitle from "../../../components/PageTitle";
import TermServices from "../../admin/services/term";
import ProfileService from "../../admin/services/profile";
import DeleteButton from "../../admin/modules/Model/components/deleted";

interface Term {
  Id: number;
  TermCode: string;
  TermName: string;
}

interface TableCardProp {
  label: string;
  data: any[];
  id: any;
  fetchData: any;
}

interface Profile {
  userId: string;
  firstName: string;
  lastName: string;
  // any other relevant properties
}

const TableCard: React.FC<TableCardProp> = ({ label, data, id, fetchData }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [terms, setTerms] = useState<Term[]>([]);
  const [selectedTerms, setSelectedTerms] = useState<Term[]>([]);
  const [lecture, setLecture] = useState<{ [key: string]: Profile }>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(6);

  const termService = new TermServices();
  const classApi = new classServices();
  const profileApi = new ProfileService();

  const fetchTerms = async () => {
    try {
      const response = await termService.getAll();
      if (response.value && Array.isArray(response.value)) {
        const validTerms = response.value.filter((t: any) => t.TermName && typeof t.TermName === 'string');
        setTerms(validTerms);
      } else {
        console.error('Received invalid or no data:', response);
        setTerms([]);
      }
    } catch (error) {
      console.error('Error fetching terms:', error);
      setTerms([]);
    }
  };

  const fetchLectureProfile = async () => {
    try {
      const response = await profileApi.getAll();
      if (response.result && Array.isArray(response.result)) {
        const lecturesById = response.result.reduce((acc: { [key: string]: Profile }, lecture: Profile) => {
          acc[lecture.userId] = lecture;
          return acc;
        }, {} as { [key: string]: Profile });
        setLecture(lecturesById);
      } else {
        console.error('Failed to fetch or invalid lecture data:', response);
        setLecture({});
      }
    } catch (error) {
      console.error('Error fetching lecture profiles:', error);
      setLecture({});
    }
  };

  useEffect(() => {
    fetchTerms();
    fetchLectureProfile();
  }, []);

  const searchProduct = (value: string) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const getFilteredData = () => {
    return data.filter(item =>
      (!searchTerm || item.ClassCode.toLowerCase().includes(searchTerm)) &&
      (!selectedTerms.length || selectedTerms.some(term => term.Id === item.Cmt.Term.Id))
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getFilteredData().slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(getFilteredData().length / itemsPerPage);

  const toggleClassStatus = async (classId: number, isOpen: boolean) => {
    try {
      if (isOpen) {
        await classApi.Close(classId);
      } else {
        await classApi.Open(classId);
      }
      fetchData();
    } catch (error) {
      console.error('Error updating class status:', error);
    }
  };

  const handleDelete = async (classId: number) => {
    try {
      await classApi.delete(classId);
      fetchData();
    } catch (error) {
      console.error('Error updating class status:', error);
    }
  };
  const generatePageNumbers = (totalPages: number): number[] => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }
  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: label, path: "/class" },
        ]}
        title={"Class Management"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="justify-content-between">
                <Col className="col-auto">
                  <div className="d-flex align-items-center justify-content-between w-200" >
                    <div className="flex-grow-1 me-2" >
                      <Typeahead
                        id="onclear-example"
                        labelKey="TermName"
                        options={terms}
                        multiple={false}
                        placeholder="Select Term..."
                        onChange={(selected) => setSelectedTerms(selected)}
                        selected={selectedTerms}
                        clearButton
                      />
                    </div>
                    <div className="flex-grow-1 me-2" style={{ width: "100%" }}>
                      <input
                        type="search"
                        className="form-control my-1 my-lg-0"
                        id="inputPassword2"
                        placeholder="Search Class..."
                        onChange={(e) => searchProduct(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>

                <Col className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <Link
                      to="/classes/create"
                      className="btn btn-success waves-effect waves-light"
                    >
                      <i className="mdi mdi-plus-circle me-1"></i> Add New
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {(currentItems || []).map((d: any, index: any) => {
          const lectureDetails = lecture[d.LectureId]; // Retrieve lecture details using LectureId
          const dateObj = new Date(d.CreatedDate);
          // Format the date as "Month day, Year" e.g., "March 10, 2021"
          const formattedDate = dateObj.toLocaleDateString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
          });
          return (

            <Col key={index} md={4} xl={4}>
              <Card className="product-box">
                <Card.Body>
                  <div className="product-action">
                    <Link
                      to={`/class/edit/${d.Id}`}
                      className="btn btn-success btn-xs waves-effect waves-light me-1"
                      style={{borderRadius: "7px"}}
                    >
                      <i className="mdi mdi-pencil"></i>
                    </Link>
                    <DeleteButton
                      variant="danger"
                      onDelete={handleDelete}
                      id = {d.Id}
                      className="btn btn-success btn-xs waves-effect waves-light me-1"
                      witdh="10px"
                      iconClass= "mdi mdi-close"
                      fetchtype = {fetchData}
                    >
                    </DeleteButton>
                  </div>
                  <div className="col">
                    <div className="m-0">
                      <Button
                        variant={d.IsOpen ? "danger" : "success"}
                        onClick={() => toggleClassStatus(d.Id, d.IsOpen)}
                      >
                        {d.IsOpen ? "Close" : "Open"}
                      </Button>

                    </div>
                  </div>
                  <div className="product-info">
                    <div className="row align-items-center">
                      <div className="col">

                        <h5 className="font-16 mt-0 sp-line-1">
                          <Link
                            to={`/class/details/${d.Id}`}
                            className="text-dark"
                          >
                            Class:  {d.ClassCode}
                          </Link>
                        </h5>
                        <h5 className="mt-1">
                          {" "}
                          <span className="text-muted">
                            {" "}
                            Term: {`${d.Cmt.Term.TermName} (${d.Cmt.Term.TermCode})`}
                          </span>
                        </h5>

                        <h5 className="mt-1">
                          {" "}
                          <span className="text-muted">
                            {" "}
                            Course : {`${d.Cmt.CourseMajor.Course.CourseName} (${d.Cmt.CourseMajor.Course.CourseCode})`}
                          </span>
                        </h5>

                        <h5 className="mt-1">
                          {" "}
                          <span className="text-muted">
                            {" "}
                            Lecture Name: {(lectureDetails as Profile)?.lastName} {(lectureDetails as Profile)?.firstName}
                          </span>
                        </h5>
                        <h5 className="mt-1">
                          {" "}
                          <span className="text-muted">
                            {" "}
                            Deleted :
                          </span>
                          <span className={`badge ${d.IsDelete ? 'bg-success' : 'bg-danger'} ms-1`}>
                            {d.IsDelete ? 'No' : 'Yes'}
                          </span>
                        </h5>
                        <div className="mt-1">
                          <span className={`badge ${d.IsOpen ? 'bg-success' : 'bg-danger'}`}>
                            {d.IsOpen ? 'Open' : 'Closed'}
                          </span>
                          <span className={`badge ms-2 ${d.IsApproval ? 'bg-success' : 'bg-warning'}`}>
                            {d.IsApproval ? 'Approved' : 'Not Approved'}
                          </span>
                        </div>
                        <h5 className="mt-3" style={{ fontSize: "12px", textAlign: "right" }}>
                          {" "}
                          <span className="text-muted">
                            {" "}
                            Create Date: {`${formattedDate}`}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Row>
        <Col>
          <ul className="pagination pagination-rounded justify-content-end mb-3">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)} aria-label="Previous">
                <span aria-hidden="true">«</span>
                <span className="visually-hidden">Previous</span>
              </Link>
            </li>
            {generatePageNumbers(totalPages).map(number => (
              <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                <Link className="page-link" to="#" onClick={() => setCurrentPage(number)}>
                  {number}
                </Link>
              </li>
            ))}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)} aria-label="Next">
                <span aria-hidden="true">»</span>
                <span className="visually-hidden">Next</span>
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TableCard;

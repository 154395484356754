import React, { useEffect, useState } from 'react'
import { Form, Button, Col, Row, Alert, Card } from 'react-bootstrap'
import PageTitle from '../../../../../components/PageTitle'
import MajorServices from '../../../services/major'
import AccountServices from '../../../services/account'
import TermServices from '../../../services/term' // Import the Term service
import { Link } from 'react-router-dom'

const FormAccountLecture = ({ formData = {}, setFormData }: any) => {
    const [major, setMajor] = useState([])
    const [terms, setTerms] = useState([]) // State to hold terms
    const [responseMessage, setResponseMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const apiAccount = new AccountServices()
    const [currentTerm, setCurrentTerm] = useState<any>()
    const fetchCurrentTerm = async () => {

        const data = await fetch(`https://crud.live:447/blms/userterm?$filter=IsCurrent eq true and userId eq '${formData.userId}'`)
        console.log("Current Term", data);
        const dataJson = await data.json();
        console.log("dataJson", dataJson);
        var term = 0;
        try {
            term = dataJson.value[0].TermId;
        } catch (error: any) {
            term = 0;
        }
        setCurrentTerm(term);
    }
    console.log("formData", formData);

    useEffect(() => {
        fetchCurrentTerm();
        console.log("currentTerm fetch", currentTerm);
        const apiMajor = new MajorServices()
        const fetchData = async () => {
            const data = await apiMajor.getAll()
            setMajor(data.value)
        }
        fetchData()
        handleFetchTerms() // Fetch terms on component mount
    }, [])

    const handleFetchTerms = async () => {
        const apiTerm = new TermServices()

        const data = await apiTerm.getAll()
        console.log("Terms", data);

        setTerms(data.value)
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const formatDate = (dateString: any) => {
        const date = new Date(dateString)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    return (
        <>
            <Row>
                <Col md={12} className='mx-auto'>
                    <Card className='shadow-sm' style={{ padding: '15px' }}>
                        <Card.Body>
                            <div className="container">
                                {responseMessage && <Alert variant="success">{responseMessage}</Alert>}
                                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formData?.email || ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                                placeholder="Enter the student's email address"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide a valid email address for the student.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="phone"
                                                value={formData?.phone || ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                                placeholder="Enter the student's phone number"
                                            />
                                            <Form.Text className="text-muted">
                                                A phone number is required for contact purposes.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Second Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="secondEmail"
                                                value={formData?.secondEmail || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the student's secondary email address"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide a secondary email address for the student.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="role"
                                                value={formData?.role || 'Student'}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="e.g., Student"
                                                readOnly={true}
                                            />
                                            <Form.Text className="text-muted">
                                                Define the role as 'Student'.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Major</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="majorId"
                                                value={formData?.majorId || ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                            >
                                                <option value="">Select Major</option>
                                                {major.map((maj: any) => (
                                                    <option key={maj.Id} value={maj.Id}>
                                                        {maj.MajorCode}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                Select the major the student is enrolled in.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Street</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="street"
                                                value={formData?.street || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the student's street address"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the student's street address.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                value={formData?.city || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the student's city"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the student's city.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="country"
                                                value={formData?.country || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the student's country"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the student's country.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Zip Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="zipCode"
                                                value={formData?.zipCode || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the student's zip code"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the student's zip code.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Institution</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="institution"
                                                value={formData?.institution || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the student's institution"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the student's institution.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Fax</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fax"
                                                value={formData?.fax || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the student's fax number"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the student's fax number.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="dateOfBirth"
                                                value={formData?.dateOfBirth ? formatDate(formData?.dateOfBirth) : ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                            />
                                            <Form.Text className="text-muted">
                                                Select the student's date of birth.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="gender"
                                                value={formData?.gender || 'Male'}
                                                onChange={handleChange}
                                                className="w-100"
                                            >
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                Select the student's gender.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Term</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="termId"
                                                value={formData?.termId || currentTerm || ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                            >
                                                <option value="">Select Term</option>
                                                {terms.map((term: any) => (
                                                    <option key={term.Id} value={term.Id}>
                                                        {term.TermName}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                Select the term the student is enrolled in.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default FormAccountLecture
import { useState, useRef, useEffect } from "react";
import Advanced from "../../../../tables/Advanced";
import { courseColumns, courseCmtColumns, courseMajorColumns } from "../data";
import cmtServices from "../../../services/cmt";
import CourseServices from "../../../services/course";
import CourseMajorServices from "../../../services/coursemajor";
import "../form.css"
import { ToastContainer, toast } from 'react-toastify';
import { Tab, Nav, Button } from "react-bootstrap";
import UpdateCourseMajorPage from "./updateCourseMajor";
import UpdateCMTPage from "./updateCMT";
import { FormRender } from "./updateCourse";

function CourseManagement() {
  const [coursesCmt, setCoursesCmt] = useState([]);
  const [coursesMajor, setCoursesMajor] = useState([]);
  const [courses, setCourses] = useState([]);

  const apiService = new cmtServices();
  const apiCourse = new CourseServices();
  const apitCM = new CourseMajorServices();
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    const data = await apiService.getAll();
    const data1 = await apiCourse.getAll();
    const data2 = await apitCM.getAll();
    console.log(data);
    if (typeof data === "string") {
      setCoursesCmt([]);
    } else {
      setCoursesCmt(data.value);
      setCourses(data1.value);
      setCoursesMajor(data2.value);
    }
    setLoading(false);
  };
  // Fetch courses
  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdate = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await apitCM.update(data.Id, data);
    console.log("Data submitted successfully:", response);
    fetchData();
  };

  const handleDelete = async (id) => {
    try {
      const response = await apitCM.delete(id);
      console.log("Data deleted successfully:", response);
    } catch (error) {
      console.error("Failed to delete data:", error);
    }
  };
  const handleUpdateCMT = async (data) => {
    console.log("Submitting data:", data);
    const add = await apitCM.getId(data?.CourseMajor?.Major?.Id, data.CourseMajor?.Course?.Id);
    const newCmt = {Id: data.Id, termId: data.Term.Id, courseMajorId: add.value[0].Id };
    console.log("Data submitted successfully:", newCmt);
    const response = await apiService.update(data.Id, newCmt);
    fetchData();
  };

  const handleDeleteCMT = async (id) => {
    try {
      const response = await apiService.delete(id);
      console.log("Data deleted successfully:", response);
    } catch (error) {
      console.error("Failed to delete data:", error);
    }
  };
  const handleUpdateCourse = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await apiCourse.update(data.Id, data);
    console.log("Data submitted successfully:", response);
    fetchData();
  };

  const handleDeleteCourse = async (id) => {
    try {
      const response = await apiCourse.delete(id);
      console.log("Data deleted successfully:", response);
    } catch (error) {
      console.error("Failed to delete data:", error);
    }
  };
  function formRender({ formData, setFormData } = {}) {
    return (<UpdateCourseMajorPage formData={formData} setFormData={setFormData} />)
  }

  function formRenderCMT({ formData, setFormData } = {}) {
    return (<UpdateCMTPage formData={formData} setFormData={setFormData} />)
  }
  function formRenderCourse({ formData, setFormData } = {}) {
    return (<FormRender formData={formData} setFormData={setFormData} />)
  }
  return (
    <div>
      <ToastContainer />
      <Tab.Container defaultActiveKey="CMT">
        <Nav variant="tabs" className="nav-bordered" as="ul">
          <Nav.Item as="li">
            <Nav.Link className="cursor-pointer" href="#" eventKey={"CMT"}>
              Course in Term
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link className="cursor-pointer" href="#" eventKey={"courseMajor"}>
              Course in Major
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link className="cursor-pointer" href="#" eventKey={"course"}>
              Course
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey={"CMT"} id={"1"}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Advanced
                title="Course in Term Management"
                link="/course-major-term/create"
                data={coursesCmt}
                columns={courseCmtColumns}
                loading={loading}
                onDelete={handleDeleteCMT}
                fetchData={fetchData}
                formRender={formRenderCMT}
                onUpdate={handleUpdateCMT}
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey={"courseMajor"} id={"2"}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Advanced
                title="Course in Major Management"
                link="/course-major/create"
                data={coursesMajor}
                columns={courseMajorColumns}
                loading={loading}
                formRender={formRender}
                onDelete={handleDelete}
                fetchData={fetchData}
                onUpdate={handleUpdate}
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey={"course"} id={"3"}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Advanced
                title="Course Management"
                link="/course/create"
                data={courses}
                columns={courseColumns}
                loading={loading}
                formRender={formRenderCourse}
                onUpdate={handleUpdateCourse}
                onDelete={handleDeleteCourse}
                fetchData={fetchData}
              />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

    </div>
  );
}

export default CourseManagement;

import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle';
import CategoryService from '../../../services/category';
import { Link, useNavigate } from 'react-router-dom';
import "../form.css"

interface Category {
    Id: number;
    CategoryName: string;
}

interface Course {
    CourseCode: string;
    CourseName: string;
}

interface CourseData {
    CategoryId?: number;
    courses: Course[];
}

const CreateCoursePage: React.FC = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [formData, setFormData] = useState<CourseData>({ courses: [{ CourseCode: '', CourseName: '' }] });
    const categoryService = new CategoryService();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            const data = await categoryService.getAll();
            setCategories(typeof data === 'string' ? [] : data.value);
        };
        fetchCategories();
    }, []);

    const handleSelectCategory = (eventKey: string | null) => {
        const category = categories.find(d => d.Id.toString() === eventKey);
        if (category) {
            setSelectedCategory(category.CategoryName);
            setFormData(prev => ({
                ...prev,
                CategoryId: category.Id
            }));
        }
    };

    const addCourse = () => {
        setFormData(prev => ({
            ...prev,
            courses: [...prev.courses, { CourseCode: '', CourseName: '' }]
        }));
    };

    const updateCourse = (index: number, key: keyof Course, value: string) => {
        const updatedCourses = formData.courses.map((course, i) =>
            i === index ? { ...course, [key]: value } : course
        );
        setFormData({ ...formData, courses: updatedCourses });
    };

    const removeCourse = (index: number) => {
        const updatedCourses = formData.courses.filter((_, i) => i !== index);
        setFormData({ ...formData, courses: updatedCourses });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const coursesToSubmit = formData.courses.map(course => ({
                categoryId: formData.CategoryId,
                courseName: course.CourseName,
                courseCode: course.CourseCode
            }));
            console.log("Submitting:", JSON.stringify(coursesToSubmit)); // Debugging
            // You need to adjust this fetch call to your API endpoint and method
            const response = await fetch('https://crud.live:447/course/add-list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(coursesToSubmit)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            navigate("/course");
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Courses', path: '/course' },
                    { label: 'Create Course', path: '/course/create', active: true },
                ]}
                title=""
            />
            <Row>
                <Col md={6} className="mx-auto">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <div className="page-title-box">
                                    <h2 className="page-title">CREATE COURSE</h2>
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Category</Form.Label>
                                    <Dropdown onSelect={handleSelectCategory}>
                                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-category">
                                            {selectedCategory || "Select Category"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {categories.map(category => (
                                                <Dropdown.Item key={category.Id} eventKey={category.Id.toString()}>
                                                    {category.CategoryName}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                                {formData.courses.map((course, index) => (
                                    <div className="term-row" key={index}>
                                        <div className="term-input">
                                            <Col sm={9}>
                                                <Form.Group className="mb-2" >
                                                    <Form.Label>Course Code</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Code"
                                                        value={course.CourseCode}
                                                        onChange={e => updateCourse(index, 'CourseCode', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </div>
                                        <div className="term-input">
                                            <Col sm={9}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Course Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Name"
                                                        value={course.CourseName}
                                                        onChange={e => updateCourse(index, 'CourseName', e.target.value)}
                                                    />
                                                </Form.Group>

                                            </Col>
                                        </div>
                                        {index > 0 ?
                                            <Button
                                                style={{ background: "none", color: "black", border: "none" }}
                                                className="mdi mdi-trash-can"
                                                onClick={() => removeCourse(index)}
                                            /> :
                                            <div style={{width: "50px"}}></div>
                                        }
                                    </div>
                                ))}
                                <Row className="mt-2">
                                    <Col className="text-center">
                                        <Button variant="secondary" onClick={addCourse} className="mt-3">+</Button>
                                        <div className=" mt-3">
                                            <Button variant="success" type="submit">
                                                <i className="fe-check-circle me-1"></i> Create
                                            </Button>
                                            <Link
                                                to="/course"
                                                className="btn btn-danger waves-effect waves-light ms-1"
                                            >
                                                <i className="fe-x me-1"></i> Cancel
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CreateCoursePage;

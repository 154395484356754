import FetchClient from "../utils/axiosClient";
import { API } from "../utils/api";


class YearServices {
    constructor() {
        this.AxiosClient = new FetchClient()
    }

    async getAll() {
        try {
            const Year = await this.AxiosClient.get(`${API.YEAR}`);
            return Year;
        } catch (error) {
            console.error("Failed to fetch Year details:", error);
            throw error;
        }
    }

    async getAllAdd() {
        try {
            const Year = await this.AxiosClient.get(`${API.YEAR}?$filter=IsDelete eq false`);
            return Year;
        } catch (error) {
            console.error("Failed to fetch Year details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const Year = await this.AxiosClient.get(`${API.YEAR}/${key}`);
            return Year;
        } catch (error) {
            console.error("Failed to fetch Year details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
            await this.AxiosClient.post(`${API.YEAR}`, data);
        } catch (error) {
            console.error("Failed to fetch Year details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const Year = await this.AxiosClient.put(`${API.YEAR}/${key}`, data);
            return Year;
        } catch (error) {
            console.error("Failed to fetch Year details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const Year = await this.AxiosClient.delete(`${API.YEAR}/${key}`);
            return Year;
        } catch (error) {
            console.error("Failed to fetch Year details:", error);
            throw error;
        }
    }

}
export default YearServices;
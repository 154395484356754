import FetchClient from "../utils/axiosClientAuthen";
import { API } from "../utils/api";


class AccountServices {
    constructor() {
        this.AxiosClient = new FetchClient()
    }

    async getAllAccountStudent() {
        try {
            const SECURITY = await this.AxiosClient.get(`${API.ACCOUNT}get-account-student`);
            return SECURITY;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllAccountLecture() {
        try {
            const SECURITY = await this.AxiosClient.get(`${API.ACCOUNT}get-account-lecturer`);
            return SECURITY;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
  

    async getDetails(key) {
        try {
            const SECURITY = await this.AxiosClient.get(`${API.ACCOUNT}${key}`);
            return SECURITY;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.ACCOUNT}add-account`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    

    async update( data) {
        try {
            const term = await this.AxiosClient.put(`${API.ACCOUNT}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async ban(key) {
        try {
            const term = await this.AxiosClient.patch(`api/profile-management/ban/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async unBan(key) {
        try {
            const term = await this.AxiosClient.patch(`api/profile-management/unban/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default AccountServices;
import React, { useState, useEffect } from "react";
import Advanced from "../../../../tables/Advanced";
import { securityColumns } from "../data";
import securityServices from "../../../services/securitylog";

function SecurityManagement() {
  const [securitys, setSecuritys] = useState([]);
  const [loading, setLoading] = useState(true);

  const securityService = new securityServices();

  const fetchSecuritys = async () => {
    setLoading(true);
    const data = await securityService.getAll();
    console.log("-------------------------------------log data");
    console.log(data);
    if (typeof data === "string") {
      setSecuritys([]);
      console.error("Failed to fetch data:", data);
    } else {
      setSecuritys(data || []);
    }
    setLoading(false);
  };

  const handleUpdate = async (data) => {
    console.log("Submitting data:", data);
    const response = await securityService.update(data.Id, data);
    console.log("Data submitted successfully:", response);
    fetchSecuritys();
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        const response = await securityService.delete(id);
        console.log("Data deleted successfully:", response);
        fetchSecuritys();
      } catch (error) {
        console.error("Failed to delete data:", error);
      }
    }
  };



  useEffect(() => {
    fetchSecuritys();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Advanced
          label="Security"
          title="Security MANAGEMENT"
          columns={securityColumns}
          data={securitys}
          isForm={false}
          isEdit={false}
          isAction={false}
          canDelete={false}
          isAdd={false}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
}

export default SecurityManagement;

import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import CourseMajorService from '../../../services/coursemajor';
import TermServices from '../../../services/term';
import MajorServices from '../../../services/major';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../form.css";

const UpdateCMTPage = ({ formData, setFormData }) => {
    const [courses, setCourses] = useState([]);
    const [terms, setTerms] = useState([]);
    const [majors, setMajors] = useState([]);
    const [selectedTermId, setSelectedTermId] = useState(formData?.Term?.Id || '');
    const [selectedMajorId, setSelectedMajorId] = useState(formData?.CourseMajor?.Major?.Id || '');
    const [selectedCourseId, setSelectedCourseId] = useState(formData?.CourseMajor?.Course?.Id || '');

    const cmService = new CourseMajorService();
    const termService = new TermServices();
    const majorService = new MajorServices();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const termData = await termService.getAll();
                const majorData = await majorService.getAll();
                setTerms(termData.value);
                setMajors(majorData.value);
            } catch (error) {
                console.error("Error fetching terms and majors:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchCourses = async () => {
            if (selectedMajorId) {
                try {
                    const courseData = await cmService.getCourseWithMajorId(selectedMajorId);
                    setCourses(courseData.value.map((item) => item.Course));
                } catch (error) {
                    console.error("Error fetching courses:", error);
                }
            } else {
                setCourses([]);
            }
        };
        fetchCourses();
    }, [selectedMajorId]);

    const handleMajorChange = (event) => {
        const selectedMajorId = event.target.value;
        setSelectedMajorId(selectedMajorId);
        setSelectedCourseId(''); // Reset course selection when major changes
        setFormData(prevState => ({
            ...prevState,
            CourseMajor: {
                ...prevState.CourseMajor,
                Major: { ...prevState.CourseMajor?.Major, Id: selectedMajorId },
                Course: null
            }
        }));
    };

    const handleTermChange = (event) => {
        const selectedTermId = event.target.value;
        setSelectedTermId(selectedTermId);
        setFormData(prevState => ({
            ...prevState,
            Term: { ...prevState.Term, Id: selectedTermId }
        }));
    };

    const handleCourseChange = (event) => {
        const selectedCourseId = event.target.value;
        setSelectedCourseId(selectedCourseId);
        setFormData(prevState => ({
            ...prevState,
            CourseMajor: {
                ...prevState.CourseMajor,
                Course: { ...prevState.CourseMajor?.Course, Id: selectedCourseId }
            }
        }));
    };

    useEffect(() => {
        setSelectedTermId(formData?.Term?.Id || '');
        setSelectedMajorId(formData?.CourseMajor?.Major?.Id || '');
        setSelectedCourseId(formData?.CourseMajor?.Course?.Id || '');
    }, [formData]);

    return (
        <Row>
            <Col md={10} className="mx-auto">
                <Card>
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Select Term:</Form.Label>
                            <Col sm={9}>
                                <Form.Select value={selectedTermId} onChange={handleTermChange}>
                                    <option value="">Select Term</option>
                                    {terms.map(term => (
                                        <option key={term.Id} value={term.Id}>{term.TermCode}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Select Major:</Form.Label>
                            <Col sm={9}>
                                <Form.Select value={selectedMajorId} onChange={handleMajorChange}>
                                    <option value="">Select Major</option>
                                    {majors.map(major => (
                                        <option key={major.Id} value={major.Id}>{major.MajorName}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        {selectedMajorId && courses.length > 0 && (
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={3}>Select Course:</Form.Label>
                                <Col sm={9}>
                                    <Form.Select
                                        value={selectedCourseId}
                                        onChange={handleCourseChange}
                                    >
                                        <option value="">Select Course</option>
                                        {courses.map(course => (
                                            <option key={course.Id} value={course.Id}>{course.CourseName}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default UpdateCMTPage;

import { useState, useEffect } from "react";
import Advanced from "../../../../tables/Advanced";
import { termColumns } from "../data";
import TermServices from "../../../services/term";
import { FormRender } from "./formrender";
function TermManagement() {
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(true);

  const termService = new TermServices();


  const fetchTerms = async () => {
    setLoading(true);
    const data = await termService.getAll();
    if (typeof data === "string") {
      setTerms([]);
      console.error("Failed to fetch data:", data);

    } else {
      setTerms(data.value);

    }
    setLoading(false);
  };



  useEffect(() => {
    fetchTerms();
  }, []);

  function formRender({ formData, setFormData } = {}) {
    return (
      <FormRender formData={formData} setFormData={setFormData} />
    )
  }

  const handleSubmit = async (data) => {
    try {
      const response = await fetch('https://crud.live:447/blms/add-list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify([data])
      });
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.status);
      }
      const text = await response.text();
      let jsonData = {};
      try {
        jsonData = JSON.parse(text); 
      } catch (error) {
        console.warn('No JSON to parse or invalid JSON:', text);
      }
      console.log('Success:', jsonData);
      fetchTerms(); // Gọi fetchTerms sau khi xử lý phản hồi
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUpdate = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await termService.update(data.Id, data);
    console.log("Data submitted successfully:", response);
    fetchTerms();
  };

  const handleDelete = async (id) => {
    
      try {
        const response = await termService.delete(id);
        console.log("Data deleted successfully:", response);
      } catch (error) {
        console.error("Failed to delete data:", error);
      }
    
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Advanced
          label="Term"
          title="TERM MANAGEMENT"
          columns={termColumns}
          data={terms}
          link="/term/create"
          formRender={formRender}
          onSubmit={handleSubmit}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fetchData={fetchTerms}
        />
      )}
    </div>
  );
}

export default TermManagement;

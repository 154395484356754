import { useState, useEffect, useRef } from "react";
import Advanced from "../../../../tables/Advanced";
import { categoryColumns } from "../data";
import Categoryyervice from "../../../services/category";
import { FormRender } from "./categoryForm";
function CategoryManagement() {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);

  const categoryService = new Categoryyervice();

  const fetchtype = async () => {
    setLoading(true);
    const data = await categoryService.getAll();
    if (typeof data === "string") {
      setCategory([]);
      console.error("Failed to fetch data:", data);

    } else {
      setCategory(data.value);

    }
    setLoading(false);
  };

  useEffect(() => {
    fetchtype();
  }, []);

  const handleSubmit = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await categoryService.create(data);
    console.log("Data submitted successfully:", response);
    fetchtype();
  };

  const handleUpdate = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await categoryService.update(data.Id, data);
    console.log("Data submitted successfully:", response);
    fetchtype();
  };


  const handleDelete = async (id) => {
    try {
      const response = await categoryService.delete(id);
      console.log("Data deleted successfully:", response);
      fetchtype();
    } catch (error) {
      console.error("Failed to delete data:", error);
    }
  };


  function formRender({ formData, setFormData } = {}) {
    return (
      <FormRender formData={formData} setFormData={setFormData} />
    )
  }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Advanced
          label="course-category"
          title="Course Category MANAGEMENT"
          link="/course-category/create"
          columns={categoryColumns}
          data={category}
          isPopup={true}
          formRender={formRender}
          onSubmit={handleSubmit}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fetchData={fetchtype}
        />
      )}
    </div>
  );
}

export default CategoryManagement;

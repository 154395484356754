import React from 'react';
import { withSwal } from 'react-sweetalert2';
import { Button } from 'react-bootstrap';
const RestoreButton = withSwal(({ swal, onDelete, id, fetchtype, title }) => (
  <Button
    variant="success"
    onClick={async () => {
      const result = await swal.fire({
        title: 'Revert this?',
        text: "",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes'
      });

      if (result.isConfirmed) {
        try {
          const response = await onDelete(id);
          console.log("Data deleted successfully:", response);
          fetchtype();
          swal.fire('Restore!', 'Restore successful.', 'success');
        } catch (error) {
          console.error("Failed to delete data:", error);
          swal.fire('Error!', 'Failed to delete data.', 'error');
        }
      }
    }}
    style={{ minWidth: "100px", borderRadius: "7px" }}
  >
    <i className="mdi mdi-restore me-1"></i>{title}
  </Button>
));

export default RestoreButton;

import { Form, Dropdown } from 'react-bootstrap';

export function FormRender({ formData, setFormData }) {

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="DepartmentName">Department Name</Form.Label>
        <Form.Control
          type="text"
          id="DepartmentName"
          value={formData?.DepartmentName || ""}
          onChange={e => setFormData({ ...formData, DepartmentName: e.target.value })}
          placeholder="Enter Department Name"
        />
      </Form.Group>
    </div>
  );
}

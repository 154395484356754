import FetchClient from "../utils/axiosClient";
import { API } from "../utils/api";

class TermServices {
    constructor() {
        this.AxiosClient = new FetchClient();
    }

    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.TERM}?$expand=Year`, { mode: 'no-cors' });
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getCount() {
        try {
            const term = await this.AxiosClient.get(`${API.TERM}/$count`, { mode: 'no-cors' });
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllAdd() {
        try {
            const term = await this.AxiosClient.get(`${API.TERM}?$filter=isDelete eq false`, { mode: 'no-cors' });
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.TERM}/${key}`, { mode: 'no-cors' });
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
            await this.AxiosClient.post(`${API.TERM}`, data, { mode: 'no-cors' });
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async createList(data) {
        try {
            const dataJson = JSON.stringify(data);
            await this.AxiosClient.post(`/add-list`, dataJson, { mode: 'no-cors' });
            console.log('Data List:', dataJson);
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.TERM}/${key}`, data, { mode: 'no-cors' });
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.TERM}/${key}`, { mode: 'no-cors' });
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
}

export default TermServices;

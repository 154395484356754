

class Scores {
    
    async fetchScoresByClassId(classId) {
        const url = `https://crud.live:450/api/Scores/get-with-class/${classId}`;

        try {
            const response = await fetch(url, {
                method: 'GET', // Phương thức GET để lấy dữ liệu
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json(); // Chuyển đổi kết quả từ JSON sang Object JavaScript
            console.log(data); // In ra dữ liệu để kiểm tra
            return data; // Trả về dữ liệu nhận được
        } catch (error) {
            console.error('Error fetching data: ', error);
            return null; // Trả về null nếu có lỗi
        }
    }

}

export default Scores;
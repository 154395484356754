import AxiosClient from "../utils/axiosClient";
import { API } from "../utils/api";


class cmtService {
    constructor() {
        this.AxiosClient = new AxiosClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJORTERM}?$expand=CourseMajor($expand=Course($expand=Category),Major)&$expand=Term`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllWithTerm(id) {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJORTERM}?$expand=CourseMajor($expand=Course($expand=Category),Major)&$expand=Term&$filter=TermId eq ${id}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllWithMajor(id, tid) {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJORTERM}?$expand=CourseMajor($expand=Course($expand=Category),Major),Term&$filter=CourseMajor/MajorId eq ${id} and TermId eq ${tid} `);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllWithThreeParam(tid, mid, cid) {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJORTERM}?$expand=CourseMajor($expand=Course($expand=Category),Major),Term&$filter=CourseMajor/MajorId eq ${mid} and TermId eq ${tid} and CourseMajor/CourseId eq ${cid} `);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJORTERM}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.COURSEMAJORTERM}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.COURSEMAJORTERM}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.COURSEMAJORTERM}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default cmtService;
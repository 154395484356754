import FetchClient from "../utils/axiosClientAuthen";
import { API } from "../utils/api";


class ProfileServices {
    constructor() {
        this.AxiosClient = new FetchClient()
    }

    async getAll() {
        try {
            const SECURITY = await this.AxiosClient.get(`${API.PROFILE}`);
            return SECURITY;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getId(userId) {
        try {
            const SECURITY = await this.AxiosClient.get(`${API.PROFILE}userId/${userId}`);
            return SECURITY;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
  

    async getDetails(key) {
        try {
            const SECURITY = await this.AxiosClient.get(`${API.PROFILE}/${key}`);
            return SECURITY;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.PROFILE}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    

    async update(data) {
        try {
            console.log("data before update", data);
            const term = await this.AxiosClient.put(`${API.PROFILE}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async fetchName(userId) {
        const url = `${API.PROFILE}userid/${userId}`
        const response = await this.AxiosClient.get(url);
        return response.result;
      }
    

}
export default ProfileServices;
import { useState, useEffect } from "react";
import Advanced from "../../../../tables/Advanced";
import { anonymousQuestionColumns, anonymousAnswerColumns } from "../data";
import { useParams } from "react-router-dom";
import { Tab, Nav, Button } from "react-bootstrap";
import contractData from '../abi.json';
import { ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../form.css';
import anonymousQuestionServices from "../../../services/anonymous";
import anonymousAnswerServices from "../../../services/anonymouseAnswer";
import ProfileServices from "../../../services/profile";
function AnonymousQuestion() {

    const [loading, setLoading] = useState(true);
    const [anonymousQuestion, setAnonymousQuestion] = useState([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [Answer, setAnswers] = useState([]);
    const profileApi = new ProfileServices();
    const anonymousApi = new anonymousQuestionServices();
    const anonymousAnswerApi = new anonymousAnswerServices();
    const fetchData = async () => {
        setLoading(true);
        const data = await anonymousApi.getAll();
        const enrollWithNames = await Promise.all(data.value.map(async (item) => {
            return {
                ...item,
                ProfileName: await fetchName(item.SenderId),
            };
        }));
        setAnonymousQuestion(enrollWithNames);
        console.log(enrollWithNames);
        setLoading(false);
    };

    const fetchName = async (userId) => {
        try {
            const response = await profileApi.fetchName(userId);
            console.log("response name", response);
            console.log(" name", response.lastName);
            return `${response.lastName} ${response.firstName}`;
        } catch (error) {
            console.error("Failed to fetch name:", error);
            return "Unknown";
        }
    };

    const handleData = async (questionId) => {
        try {
            const answersData = await anonymousAnswerApi.getAll(questionId);
            const enrollWithNames = await Promise.all(answersData.value.map(async (item) => {
                return {
                    ...item,
                    ProfileName: await fetchName(item.AnswererId),
                };
            }));
            setAnswers(enrollWithNames);
            setSelectedQuestionId(questionId);
            console.log(enrollWithNames);
        } catch (error) {
            console.error("Failed to fetch answers:", error);
            toast.error("Could not fetch answers.");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdate = async (data) => {
        console.log("Submitting data:", data.value);

        console.log("Data submitted successfully:");
        fetchData();
    };

    const handleDelete = async (id) => {
        try {
            const response = await anonymousAnswerApi.delete(id);
            handleData(selectedQuestionId);
            console.log("Data deleted successfully:", id);
        } catch (error) {
            console.error("Failed to delete data:", error);
        }
    };

    const handleDeleteQuestion = async (id) => {
        try {
            const response = await anonymousApi.delete(id);
            console.log("Data deleted successfully:", id);
        } catch (error) {
            console.error("Failed to delete data:", error);
        }
    };

    const formRender = ({ formData, setFormData } = {}) => {
        // Your form rendering logic here
    };



    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Advanced
                        title="Question Management"
                        columns={anonymousQuestionColumns}
                        data={anonymousQuestion}
                        isAdd={false}
                        isSelect={true}
                        isEdit={false}
                        formRender={formRender}
                        onUpdate={handleUpdate}
                        onDelete={handleDeleteQuestion}
                        moreData={handleData}
                        fetchData={fetchData}
                        buttonTitle="View Answer"
                    />
                    {selectedQuestionId &&
                        (
                            Answer.length > 0 ? (
                                <Advanced
                                    title={`Answers for the question: ${Answer[0]?.Question?.QuestionContent}`}
                                    columns={anonymousAnswerColumns}
                                    data={Answer}
                                    isAdd={false}
                                    isEdit={false}
                                    formRender={formRender}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}
                                    fetchData={fetchData}
                                />
                            ) : (
                                <Advanced
                                    title="No answers for this question"
                                    columns={anonymousAnswerColumns}
                                    data={Answer}
                                    isAdd={false}
                                    isEdit={false}
                                    formRender={formRender}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}
                                />
                            )
                        )}
                </>
            )}
        </div>
    );
}

export default AnonymousQuestion;

import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export function UpdateContentForm({ formData, setFormData }: any) {
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    return (
        <div>
            <Form>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="topic">Topic</Form.Label>
                            <Form.Control
                                type="text"
                                id="topic"
                                name="Topic"
                                value={formData?.Topic || ""}
                                onChange={handleChange}
                                placeholder="Enter Topic"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="contentName">Content Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="contentName"
                                name="ContentName"
                                value={formData?.ContentName || ""}
                                onChange={handleChange}
                                placeholder="Enter Content Name"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="startDate">Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                id="startDate"
                                name="StartDate"
                                value={formData?.StartDate ? formatDate(formData?.StartDate) : formData?.StartDate || ""}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="dueDate">Due Date</Form.Label>
                            <Form.Control
                                type="date"
                                id="dueDate"
                                name="DueDate"
                                value={formData?.DueDate ? formatDate(formData?.DueDate) : formData?.DueDate || ""}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description">Description</Form.Label>
                            <Form.Control
                                type="text"
                                id="description"
                                name="Description"
                                value={formData?.Description || ""}
                                onChange={handleChange}
                                placeholder="Enter Description"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="tag">Tag</Form.Label>
                            <Form.Control
                                type="text"
                                id="tag"
                                name="Tag"
                                value={formData?.Tag || ""}
                                onChange={handleChange}
                                placeholder="Enter Tag"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="mandatory"
                                name="IsMandatory"
                                label="Mandatory"
                                checked={formData?.IsMandatory || false}
                                onChange={(e) => setFormData({ ...formData, IsMandatory: e.target.checked })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="IsActive"
                                name="IsActive"
                                label="Active"
                                checked={formData?.IsActive || false}
                                onChange={(e) => setFormData({ ...formData, IsActive: e.target.checked })}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

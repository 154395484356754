import { useState, useEffect } from "react";
import Advanced from "../../../../tables/Advanced";
import { enrollColumns, dClassColumns, discussionQuestionColumns, discussionAnswerColumns, contentColumns } from "../data";
import EnrollmentServices from "../../../services/enrollment";
import DirectoryClassServices from "../../../services/directoryClass";
import Scores from "../../../services/score";
import { useParams } from "react-router-dom";
import { Tab, Nav, Button } from "react-bootstrap";
import ScoreTable from "./ScoreTable";
import contractData from '../abi.json';
import { ethers, providers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../form.css';
import disscusionQuestionServices from "../../../services/discussionQuestion";
import disscusionANSWERServices from "../../../services/discussionAnswer";
import ContentServices from "../../../services/content";
import ProfileServices from "../../../services/profile";
import { FormRender } from "./updateDiscussion";
import { FormRenderDirectory } from "./updateDirectory";
import { UpdateContentForm } from "./updateContent";
import FileServices from "../../../services/file";

function ClassDetails() {
  const [enroll, setEnroll] = useState([]);
  const [dClass, setDClass] = useState([]);
  const [score, setScore] = useState([]);
  const [scoreName, setScoreName] = useState([]);
  const [loading, setLoading] = useState(true);
  const [discussion, setDiscussion] = useState([]);

  const [Answer, setAnswers] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [selectedContentId, setSelectedContentId] = useState(null);

  const [content, setContent] = useState([]);
  const { classId } = useParams();

  const enrollment = new EnrollmentServices();
  const dClassdata = new DirectoryClassServices();
  const score1 = new Scores();
  const discussionData = new disscusionQuestionServices();
  const disAnswer = new disscusionANSWERServices();
  const contentData = new ContentServices();
  const profileApi = new ProfileServices();
  const apiFile = new FileServices();
  const [isScore, setIsScore] = useState(false);
  const [hash, setHash] = useState("");
  const fetchData = async () => {
    const checkScore = await fetch(`https://storehash.onrender.com/api/hash/${classId}`);
    setIsScore(checkScore ? true : false);
    console.log("Check score", checkScore);
    console.log("isScore", isScore);
    
    if (checkScore) {
      const dataHash = await checkScore.json();
      console.log("Data hash", dataHash);
      
      setHash(dataHash.hash);
    }
    console.log("Data hash", hash);
    
    setLoading(true);
    const dataEnroll = await enrollment.getAllStudennt(classId);
    const dataDClass = await dClassdata.getAllWithClass(classId);
    const dataScore = await score1.fetchScoresByClassId(classId);
    const dataDis = await discussionData.getAllWithClass(classId);
    console.log("data enroll", dataEnroll.value);

    const enrollWithNames = await Promise.all(dataEnroll.value.map(async (item) => {
      return {
        ...item,
        ProfileName: await fetchName(item.UserId),
      };
    }));

    const ScoreWithNames = dataScore ? await Promise.all(dataScore.scores?.map(async (item) => ({
      ...item,
      ProfileName: await fetchName(item.userId),
    }))) : [];

    console.log(dataScore);
    setEnroll(enrollWithNames);
    setDClass(dataDClass.value);
    setScoreName(ScoreWithNames);
    setScore(dataScore);
    setDiscussion(dataDis.value);

    setLoading(false);
  };

  const fetchFile = async (fileId) => {
    const data = await apiFile.getDetails(fileId);
    console.log(data);

    console.log(data);
    return data;
  }

  const handleData = async (questionId) => {
    try {
      const answersData = await disAnswer.getAllWithQuestion(questionId);
      const discussionWithNames = await Promise.all(answersData.value.map(async (item) => {
        return {
          ...item,
          ProfileName: await fetchData(item.UserId),
        };
      }));
      setAnswers(discussionWithNames);
      setSelectedQuestionId(questionId);
      console.log(discussionWithNames);
    } catch (error) {
      console.error("Failed to fetch answers:", error);
      toast.error("Could not fetch answers.");
    }
  };

  const handleContent = async (directoryId) => {
    try {
      const contentsData = await contentData.get(directoryId);
      const contentWithFile = await Promise.all(contentsData.value.map(async (item) => {
        if (item.FileId === null) {
          return {
            ...item,

          };
        }
        else {
          return {
            ...item,
            File: await fetchFile(item?.FileId),
          };
        }
      }))
      setContent(contentWithFile);
      console.log(contentWithFile);

      setSelectedContentId(directoryId);
      console.log("Failed to fetch answers:", contentsData.value);

    } catch (error) {
      console.error("Failed to fetch answers:", error);
      toast.error("Could not fetch answers.");
    }
  };

  const fetchName = async (userId) => {
    try {
      const response = await profileApi.fetchName(userId);

      return `${response.lastName} ${response.firstName}`;
    } catch (error) {
      console.error("Failed to fetch name:", error);
      return "Unknown";
    }
  };


  useEffect(() => {
    fetchData();
  }, []);



  const handleSubmit = async (data) => {
    console.log("Submitting data:", data.value);

    console.log("Data submitted successfully:");
    fetchData();
  };

  const handleUpdate = async (data) => {
    console.log("Submitting data:", data.value);
    console.log("Data submitted successfully:");
    fetchData();
  };



  const handleUpdateDiscus = async (data) => {
    console.log("Submitting data:", data.value);
    await discussionData.update(data.Id, data);
    fetchData();
    console.log("Data submitted successfully:");
    fetchData();
  };

  const handleUpdateDirectory = async (data) => {
    console.log("Submitting data:", data.value);
    await dClassdata.update(data.Id, data);
    fetchData();
    console.log("Data submitted successfully:");
    fetchData();
  };

  const handleDelete = async () => {
    // Your delete logic here
  };
  const handleDeleteDis = async (id) => {
    await discussionData.delete(id);

  };

  const handleDeleteAnswer = async (id) => {
    await disAnswer.delete(id);
    handleData(selectedQuestionId);
  };

  const handleDeleteDrirec = async (id) => {
    await dClassdata.delete(id);
  };

  const handleUpdateContent = async (data) => {
    await contentData.update(data.Id, data);
    handleContent(selectedContentId);
  };

  const handleDeleteContent = async (id) => {
    await contentData.delete(id);
    handleContent(selectedContentId);
  };

  function formRender({ formData, setFormData } = {}) {
    return (
      <FormRender formData={formData} setFormData={setFormData} />
    )
  }

  function formRenderDirectory({ formData, setFormData } = {}) {
    return (
      <FormRenderDirectory formData={formData} setFormData={setFormData} />
    )
  }
  function formRenderContent({ formData, setFormData } = {}) {
    return (
      <UpdateContentForm formData={formData} setFormData={setFormData} />
    )
  }

  // Contract details
  const contractAddress = "0x45b94c53dde5e056e746d535ff063f7363dd50ac";
  const providerUrl = "https://rpc.ankr.com/fantom_testnet";
  const privateKey = "ea99e29c7735a7dd37e780fb40e2cf342c491d795709782af65ba63e6afb7e6d";
  const provider = new ethers.providers.JsonRpcProvider(providerUrl);
  const signer = new ethers.Wallet(privateKey, provider);
  const gradeManagement = new ethers.Contract(contractAddress, contractData.abi, signer);

  const OnSubmitBlockchain = async (scoreData) => {
    try {
      console.log("Submitting to blockchain with data:", scoreData);

      const id = scoreData.id;
      const classId = scoreData.classId;

      const scoreIds = scoreData.scores.map(score => score.scoreId);
      const userIds = scoreData.scores.map(score => score.userId);
      const typeScores = scoreData.scores.map(score => score.typeScore);

      // Log values to ensure they are not undefined
      console.log("id:", id);
      console.log("classId:", classId);
      console.log("scoreIds:", scoreIds);
      console.log("userIds:", userIds);
      console.log("typeScores:", typeScores);

      if (!id || !classId || !scoreIds || !userIds || !typeScores) {
        throw new Error("Missing required fields");
      }

      const tx = await gradeManagement.addScoreInClass(id, classId, scoreIds, userIds, typeScores);
      console.log("Transaction submitted:", tx.hash);
      const receipt = await tx.wait();
      console.log("Transaction confirmed:", receipt);
      console.log("Store transaction hash", receipt);
      const dataStore = {
        classId: classId,
        hash: receipt.transactionHash,
      };
      const storeHash = await fetch("https://storehash.onrender.com/api/hash", 
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataStore),
        }
      );
      console.log("Store hash:", storeHash);
      if (storeHash) {
        toast.success(`Transaction successful! Hash: ${tx.hash}`);
        fetchData();
      }
      else {
        toast.error("Error submitting to blockchain. Check console for details.");
      }
    } catch (error) {
      console.error("Error submitting to blockchain:", error);
      toast.error("Error submitting to blockchain. Check console for details.");
    }
  };

  return (
    <div>

      <ToastContainer />
      <Tab.Container defaultActiveKey="Member">
        <Nav variant="tabs" className="nav-bordered" as="ul">
          <Nav.Item as="li">
            <Nav.Link className="cursor-pointer" href="#" eventKey={"Member"}>
              Member
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link className="cursor-pointer" href="#" eventKey={"Discussion"}>
              Discussion
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link className="cursor-pointer" href="#" eventKey={"Directory"}>
              Directory
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link className="cursor-pointer" href="#" eventKey={"Score"}>
              Score
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey={"Member"} id={"1"}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Advanced
                title="Member in Class Management"
                columns={enrollColumns}
                label={'Class'}
                data={enroll}
                isAdd={false}
                formRender={formRender}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                isAction={false}
              />
            )}

          </Tab.Pane>
          <Tab.Pane eventKey={"Discussion"} id={"2"}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <Advanced
                  title="Discussion Question in Class Management"
                  columns={discussionQuestionColumns}
                  data={discussion}
                  isAdd={false}
                  isSelect={true}
                  formRender={formRender}
                  onUpdate={handleUpdateDiscus}
                  onDelete={handleDeleteDis}
                  fetchData={fetchData}
                  moreData={handleData}
                  buttonTitle="View Answer"
                />
                {selectedQuestionId &&
                  <Advanced
                    title={`Answers for the question: ${Answer[0]?.DisQuestion?.QuestionContent}`}
                    columns={discussionAnswerColumns}
                    data={Answer}
                    isAdd={false}
                    formRender={formRender}
                    onUpdate={handleUpdate}
                    onDelete={handleDeleteAnswer}
                    isEdit={false}
                    fetchData={fetchData}
                  />}
              </>
            )}
          </Tab.Pane>

          <Tab.Pane eventKey={"Directory"} id={"4"}>

            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <Advanced
                  title="Directory in Class Management"
                  columns={dClassColumns}
                  data={dClass}
                  isAdd={false}
                  isSelect={true}
                  formRender={formRenderDirectory}
                  onUpdate={handleUpdateDirectory}
                  onDelete={handleDeleteDrirec}
                  moreData={handleContent}
                  fetchData={fetchData}
                  buttonTitle="View Content"
                />
                {selectedContentId && (
                  <Advanced
                    title={`Content`}
                    columns={contentColumns}
                    data={content}
                    isAdd={false}
                    formRender={formRenderContent}
                    onUpdate={handleUpdateContent}
                    onDelete={handleDeleteContent}
                    fetchData={fetchData}
                    style={{
                      minWidth: '1000px'
                    }}
                  />
                )}
              </>
            )}

          </Tab.Pane>
          <Tab.Pane eventKey={"Score"} id={"5"}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <ScoreTable hash={hash} isScore={isScore} data={{ scores: scoreName }} onHandleBlockchain={() => OnSubmitBlockchain(score)} />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default ClassDetails;

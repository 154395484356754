import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle';
import CourseMajorService from '../../../services/coursemajor';
import TermServices from '../../../services/term';
import MajorServices from '../../../services/major';
import { Link, useNavigate } from 'react-router-dom';
import "../form.css";
import cmtService from '../../../services/cmt';
interface Term {
    Id: number;
    TermCode: string;
    TermName: string;
}

interface Course {
    Id: number;
    CourseName: string;
    CourseCode: string;
    CategoryId: number;
}

interface Major {
    Id: number;
    MajorCode: string;
    MajorName: string;
}

interface Cmt {
    termId: number;
    CourseMajorId: number;
}

const CreateCMTPage: React.FC = () => {
    const [courses, setCourses] = useState<Course[]>([]);
    const [terms, setTerms] = useState<Term[]>([]);
    const [majors, setMajors] = useState<Major[]>([]);
    const [selectedTermId, setSelectedTermId] = useState<number | null>(null);
    const [selectedMajorId, setSelectedMajorId] = useState<number | null>(null);
    const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);


    const cmtService1 = new cmtService();
    const cmService = new CourseMajorService();
    const termService = new TermServices();
    const majorService = new MajorServices();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const termData = await termService.getAll();
            const majorData = await majorService.getAll();
            setTerms(termData.value);
            setMajors(majorData.value);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchCourses = async () => {
            if (selectedMajorId) {
                const courseData = await cmService.getCourseWithMajorId(selectedMajorId);
                setCourses(courseData.value.map((item: any) => item.Course));
            }
        };
        fetchCourses();
    }, [selectedMajorId]);

    const handleMajorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const majorId = parseInt(e.target.value);
        setSelectedMajorId(majorId);
    };

    const handleTermChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const termId = parseInt(e.target.value);
        setSelectedTermId(termId);
    };

    const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const courseId = parseInt(e.target.value);
        setSelectedCourseId(courseId);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const add = await cmService.getId(selectedMajorId, selectedCourseId);
        const newCmt = { termId: selectedTermId, CourseMajorId: add.value[0].Id };
        await cmtService1.create(newCmt);
        console.log(newCmt);
        navigate("/course");
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Course', path: '/course' },
                    { label: 'Create Course Major Term', path: '/course-major-term/create', active: true },
                ]}
                title=""
            />
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <div className="page-title-box">
                                    <h2 className="page-title">Create Course Major Term</h2>
                                </div>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={3}>Select Term:</Form.Label>
                                    <Col sm={9}>
                                        <Form.Select value={selectedTermId || ''} onChange={handleTermChange}>
                                            <option value="">Select Term</option>
                                            {terms.map(term => (
                                                <option key={term.Id} value={term.Id}>{term.TermName}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={3}>Select Major:</Form.Label>
                                    <Col sm={9}>
                                        <Form.Select value={selectedMajorId || ''} onChange={handleMajorChange}>
                                            <option value="">Select Major</option>
                                            {majors.map(major => (
                                                <option key={major.Id} value={major.Id}>{major.MajorName}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                {selectedMajorId && courses.length > 0 && (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={3}>Select Course:</Form.Label>
                                        <Col sm={9}>
                                            <Form.Select
                                                value={selectedCourseId || ""}
                                                onChange={handleCourseChange}
                                            >
                                                <option value="">Select Course</option>
                                                {courses.map(course => (
                                                    <option key={course.Id} value={course.Id}>{course.CourseName}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                )}
                                <Row className="mt-5">
                                    <Col className="text-center">
                                        <Button variant="success" type="submit">
                                            <i className="fe-check-circle me-1"></i> Create
                                        </Button>
                                        <Link to="/course" className="btn btn-danger ms-2">Back</Link>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CreateCMTPage;

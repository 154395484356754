import AxiosClient from "../utils/axiosClient";
import { API } from "../utils/api";


class CourseMajorServices {
    constructor() {
        this.AxiosClient = new AxiosClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJOR}?$expand=Course($expand=Category),Major`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getCourseWithMajorId(id) {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJOR}?$expand=Course($expand=Category),Major&$filter=MajorId eq ${id}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
    async getId( mid, cid) {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJOR}?$expand=Course($expand=Category),Major&$filter=MajorId eq ${mid} and CourseId eq ${cid}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.COURSEMAJOR}/${key}$expand=Course($expand=Category),Major&$filter=MajorId`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.COURSEMAJOR}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.COURSEMAJOR}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.COURSEMAJOR}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default CourseMajorServices;
import React from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';

export function FormRender({ formData, setFormData }) {
  const handleYearChange = (date) => {
    if (date) {
      const year = date.getFullYear().toString();
      setFormData(prevState => ({ ...prevState, Year: year }));
    } else {
      setFormData(prevState => ({ ...prevState, Year: '' }));
    }
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="year">Year</Form.Label>
        <ReactDatePicker
          selected={formData?.Year ? new Date(parseInt(formData?.Year), 0, 1) : null}
          onChange={handleYearChange}
          showYearPicker
          dateFormat="yyyy"
          className="form-control"
          placeholderText="Select Year"
          yearItemNumber={9}
        />
      </Form.Group>
    </div>
  );
}

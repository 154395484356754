import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Alert, Card } from 'react-bootstrap';
import MajorServices from '../../../services/major';
import AccountServices from '../../../services/account';

const FormAccountLecture = ({ formData = {}, setFormData }: any) => {
    const [major, setMajor] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const apiAccount = new AccountServices();

    useEffect(() => {
        const apiMajor = new MajorServices();
        const fetchData = async () => {
            const data = await apiMajor.getAll();
            setMajor(data.value);
        };
        fetchData();
    }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <>
            <Row>
                <Col md={12} className='mx-auto'>
                    <Card className='shadow-sm' style={{ padding: '15px' }}>
                        <Card.Body>
                            <div className="container">
                                {responseMessage && <Alert variant="success">{responseMessage}</Alert>}
                                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formData?.email || ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                                placeholder="Enter the lecturer's email address"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide a valid email address for the lecturer.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="phone"
                                                value={formData?.phone || ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                                placeholder="Enter the lecturer's phone number"
                                            />
                                            <Form.Text className="text-muted">
                                                A phone number is required for contact purposes.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Second Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="secondEmail"
                                                value={formData?.secondEmail || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the lecturer's secondary email address"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide a secondary email address for the lecturer.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="role"
                                                value={formData?.role || 'Lecturer'}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="e.g., Lecturer"
                                                readOnly={true}
                                            />
                                            <Form.Text className="text-muted">
                                                Define the role as 'Lecturer'.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Major</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="majorId"
                                                value={formData?.majorId || ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                            >
                                                <option value="">Select Major</option>
                                                {major.map((maj: any) => (
                                                    <option key={maj.Id} value={maj.Id}>
                                                        {maj.MajorCode}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                Select the major the lecturer is teach in.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Street</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="street"
                                                value={formData?.street || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the lecturer's street address"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the lecturer's street address.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                value={formData?.city || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the lecturer's city"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the lecturer's city.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="country"
                                                value={formData?.country || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the lecturer's country"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the lecturer's country.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Zip Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="zipCode"
                                                value={formData?.zipCode || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the lecturer's zip code"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the lecturer's zip code.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Institution</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="institution"
                                                value={formData?.institution || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the lecturer's institution"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the lecturer's institution.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Fax</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fax"
                                                value={formData?.fax || ''}
                                                onChange={handleChange}
                                                className="w-100"
                                                placeholder="Enter the lecturer's fax number"
                                            />
                                            <Form.Text className="text-muted">
                                                Provide the lecturer's fax number.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="dateOfBirth"
                                                value={formData?.dateOfBirth ? formatDate(formData?.dateOfBirth) : ''}
                                                onChange={handleChange}
                                                required
                                                className="w-100"
                                            />
                                            <Form.Text className="text-muted">
                                                Select the lecturer's date of birth.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="gender"
                                                value={formData?.gender || 'Male'}
                                                onChange={handleChange}
                                                className="w-100"
                                            >
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                Select the lecturer's gender.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default FormAccountLecture;

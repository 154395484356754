import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import PageTitle from '../../../../../components/PageTitle';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classServices from '../../../services/class';
import CmtService from '../../../services/cmt';
import TermServices from '../../../services/term';
import AccountServices from '../../../services/account';
import ProfileServices from '../../../services/profile';

interface IFormInput {
    Id: any;
    CmtId: number;
    LectureId: string;
    ClassCode: string;
    isOpen: boolean;
    isApproval: boolean;
}

interface ITerm {
    Id: any;
    TermCode: any;
    TermName: any;
}

interface IMajor {
    Id: any;
    MajorCode: any;
    MajorName: any;
}

interface ICourse {
    Id: any;
    CourseCode: any;
}

interface ICourseMajor {
    Id: number;
    MajorId: number;
    CourseId: number;
    Major: IMajor;
    Course: ICourse;
}

interface ILecture {
    id: string;
    lastName: string;
}

interface IProfile {
    userId: string;
    lastName: string;
    result: any;
}

// Validation Schema
const schema = yup.object().shape({
    LectureId: yup.string().required('Please select Lecture '),
    ClassCode: yup.string().required('Please enter a Class Code'),
    isOpen: yup.boolean(),
    isApproval: yup.boolean(),
});

const UpdateClassForm = () => {
    const { classId } = useParams();
    const navigate = useNavigate();
    const apiClass = new classServices();
    const apiCmt = new CmtService();
    const apiTerm = new TermServices();
    const apiAccount = new AccountServices();
    const apiProfile = new ProfileServices();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [terms, setTerms] = useState<ITerm[]>([]);
    const [majors, setMajors] = useState<IMajor[]>([]);
    const [courses, setCourses] = useState<ICourse[]>([]);
    const [selectedTerm, setSelectedTerm] = useState<ITerm[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<IMajor[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<ICourse[]>([]);
    const [selectedCourseMajor, setSelectedCourseMajor] = useState<ICourseMajor[]>([]);
    const [lectures, setLectures] = useState<ILecture[]>([]);
    const [selectedLecture, setSelectedLecture] = useState<ILecture[]>([]);

    const loadClassData = async () => {
        try {
            const response = await apiClass.getDetails(classId);
            const classData = response;

            if (classData) {
                setValue('LectureId', classData.LectureId);
                setValue('ClassCode', classData.ClassCode);
                setValue('isOpen', classData.IsOpen);
                setValue('isApproval', classData.IsApproval);
                setSelectedTerm([{
                    Id: classData.Cmt.Term.Id,
                    TermCode: classData.Cmt.Term.TermCode,
                    TermName: classData.Cmt.Term.TermName
                }]);

                if (classData.Cmt && classData.Cmt.CourseMajor) {
                    setSelectedMajor([{
                        Id: classData.Cmt.CourseMajor.MajorId,
                        MajorCode: classData.Cmt.CourseMajor.Major.MajorCode,
                        MajorName: classData.Cmt.CourseMajor.Major.MajorName
                    }]);
                    setSelectedCourse([{
                        Id: classData.Cmt.CourseMajor.CourseId,
                        CourseCode: classData.Cmt.CourseMajor.Course.CourseCode
                    }]);
                }

                if (classData.LectureId) {
                    const lecture = lectures.find(l => l.id === classData.LectureId);
                    if (lecture) {
                        setSelectedLecture([lecture]);
                    }
                }
            } else {
                console.error('Class data not found:', response);
                navigate('/class');
            }
        } catch (error) {
            console.error('Failed to load class data:', error);
            navigate('/class');
        }
    };

    const fetchLectures = async () => {
        try {
            const response = await apiAccount.getAllAccountLecture();
            if (!response || !response.result) {
                console.error("Failed to fetch lectures or incorrect data format:", response);
                setLectures([]);
                return;
            }

            const lectureAccounts: ILecture[] = response.result;

            const profiles: IProfile[] = await Promise.all(
                lectureAccounts.map(lecture => apiProfile.getId(lecture.id))
            );

            const lecturesWithNames = lectureAccounts.map((lecture, index) => {
                const profile = profiles[index].result;
                return {
                    id: lecture.id,
                    lastName: profile.lastName || "No Last Name"
                };
            });

            setLectures(lecturesWithNames);
        } catch (error) {
            console.error("Error fetching lectures:", error);
            setLectures([]);
        }
    };

    const fetchTerms = async () => {
        try {
            const data = await apiTerm.getAll();
            if (data && data.value) {
                const filteredTerms = data.value.filter((term: any) => term.TermCode && typeof term.TermCode === 'string');
                setTerms(filteredTerms);
            } else {
                setTerms([]);
                console.error("Failed to fetch terms or incorrect data format:", data);
            }
        } catch (error) {
            console.error("Error fetching terms:", error);
            setTerms([]);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchTerms();
            await fetchLectures();
            if (classId && lectures.length > 0) {
                loadClassData();
            }
        };

        fetchData();
    }, [classId, lectures.length]);



    const handleTermSelect = async (selected: ITerm[]) => {
        setSelectedTerm(selected);
        setMajors([]);
        setCourses([]);
        setSelectedMajor([]); // Also clear selected major state
        setSelectedCourse([]); // Also clear selected course state
        if (selected.length) {
            try {
                const data = await apiCmt.getAllWithTerm(selected[0].Id);
                if (data && data.value) {
                    const majorsData = data.value.map((item: any) => item.CourseMajor.Major);
                    setMajors(majorsData);
                    setSelectedCourseMajor(data.value);
                } else {
                    setMajors([]);
                    console.error("Failed to fetch majors or incorrect data format:", data);
                }
            } catch (error) {
                console.error("Error fetching majors:", error);
                setMajors([]);
            }
        }
    };

    const handleMajorSelect = async (selectedMajor: IMajor[]) => {
        setSelectedMajor(selectedMajor);
        setCourses([]);
        setSelectedCourse([]); 
        try {
            const data = await apiCmt.getAllWithMajor(selectedTerm[0].Id);
            if (data && data.value) {
                const filteredCourses = data.value.map((item: any) => item.CourseMajor.Course);
                setCourses(filteredCourses);
            } else {
                setCourses([]);
                console.error("Failed to fetch courses or incorrect data format:", data);
            }
        } catch (error) {
            console.error("Error fetching courses:", error);
            setCourses([]);
        }
    };

    const handleLectureSelect = (selected: ILecture[]) => {
        setSelectedLecture(selected);

        setValue('LectureId', selected[0]?.id);

    };

    const onSubmit = async (data: IFormInput) => {
        try {
            if (selectedTerm.length === 0 || selectedMajor.length === 0 || selectedCourse.length === 0) {
                console.error("Please select a term, major, and course.");
                return;
            }
            const data1 = await apiCmt.getAllWithThreeParam(selectedTerm[0].Id, selectedMajor[0].Id, selectedCourse[0].Id);
            if (data1.value && data1.value.length > 0) {
                data.CmtId = data1.value[0].Id;
                data.Id = classId;
                await apiClass.update(classId, data);
                navigate("/class");
            }
        } catch (error) {
            console.error("Error during submission:", error);
            alert("Failed to update class details. Please try again later.");
        }
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Classes', path: '/class' },
                    { label: 'Update Class', path: '/apps/classes/create', active: true },
                ]}
                title={''}
            />
            <Row>
                <Col md={6} className="mx-auto">
                    <Card>
                        <Card.Body>
                            <div className="page-title-box">
                                <h2 className="page-title">Update Class</h2>
                            </div>
                            <div className='mb-1' style={{ alignItems: 'baseline', fontSize: "12px" }}>
                                <span style={{ marginRight: '10px', fontWeight: "bold" }}>* NOTICE:</span>
                                <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                                    Start by selecting the academic term. This choice sets the timeframe for your class and helps streamline the selection of majors relevant to the chosen term.
                                </p>
                                <p className="text-muted" style={{ margin: '0', fontSize: "12px" }}>
                                    Next, select a major. Choosing the right major is crucial as it determines the specific courses available for your class within the selected academic term.
                                </p>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Term</Form.Label>
                                    <Typeahead
                                        id="select-term"
                                        labelKey="TermCode"
                                        options={terms}
                                        placeholder="Select a term..."
                                        onChange={handleTermSelect}
                                        selected={selectedTerm}
                                        clearButton
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Major</Form.Label>
                                    <Typeahead
                                        id="select-major"
                                        labelKey="MajorCode"
                                        options={majors}
                                        placeholder="Select a major..."
                                        onChange={(selected) => handleMajorSelect(selected)}
                                        selected={selectedMajor}
                                        clearButton
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Course</Form.Label>
                                    <Typeahead
                                        id="select-course"
                                        labelKey="CourseCode"
                                        options={courses}
                                        placeholder="Select a course..."
                                        onChange={(selected) => setSelectedCourse(selected)}
                                        selected={selectedCourse}
                                        clearButton
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Lecture</Form.Label>
                                    <Typeahead
                                        id="select-lecture"
                                        labelKey="lastName"
                                        options={lectures}
                                        placeholder="Select a lecture..."
                                        onChange={handleLectureSelect}
                                        selected={selectedLecture}
                                        clearButton
                                    />
                                    {errors.LectureId && <p className="text-danger">{errors.LectureId.message}</p>}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Class Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Class Code" {...register('ClassCode')} />
                                    {errors.ClassCode && <p className="text-danger">{errors.ClassCode.message}</p>}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check label="Is Approval Needed" {...register('isApproval')} />
                                </Form.Group>

                                <Row className="mt-2">
                                    <Col className="text-center">
                                        <Button
                                            variant="success"
                                            type="submit"
                                            className="waves-effect waves-light m-1"
                                        >
                                            <i className="fe-check-circle me-1"></i> Update
                                        </Button>
                                        <Link to="/class" className="btn btn-danger waves-effect waves-light">
                                            <i className="fe-x me-1"></i> Cancel
                                        </Link>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default UpdateClassForm;


export const API = {
    SIGN_IN: `api/authentication/login`,
    DEPARTMENT: `blms/Department`,
    MAJOR: `blms/Major`,
    TERM: `blms/Term`,
    YEAR: `blms/AcademicYear`,
    DIRECTORYCLASS: `blms/DirectoryClass`,
    DIRECTORYTYPE: `blms/DirectoryType`,
    CLASS: `blms/Class`,
    SCORE: `blms/SCORE`,
    SUBMISSION: `blms/SUBMISSION`,
    COURSEMAJORTERM: `blms/CoursesMajorTerm`,
    COURSEMAJOR: `blms/coursesMajor`,
    COURSE: `blms/Course`,
    COURSECATEGORY: `blms/CourseCategory`,
    ENROLLMENT: `blms/Enrollment`,
    DISCUSSIONANSWER: `blms/DiscussionAnswer`,
    DISCUSSIONQUESTION: `blms/DiscussionQuestion`,
    ANONYMOUSANSWER: `blms/AnonymousAnswer`,
    ANONYMOUSQUESTION: `blms/AnonymousQuestion`,
    CONTENT: `blms/Content`,
    SECURITY: `api/SecurityLogs`,
    ACCOUNT: `api/AccountManagement/`,
    PROFILE: `api/profile-management/`,
    FILE: `/api/File`
}
import AxiosClient from "../utils/axiosClient";
import { API } from "../utils/api";


class DirectoryClassServices {
    constructor() {
        this.AxiosClient = new AxiosClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.DIRECTORYCLASS}?$expand=DirectoryType&$expand=Class`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getAllWithClass(id) {
        try {
            const term = await this.AxiosClient.get(`${API.DIRECTORYCLASS}?$expand=DirectoryType&$expand=Class&$filter=ClassId eq ${id}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.DIRECTORYCLASS}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.DIRECTORYCLASS}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.DIRECTORYCLASS}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.DIRECTORYCLASS}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default DirectoryClassServices;
import AxiosClient from "../utils/axiosClient";
import { API } from "../utils/api";


class anonymousQuestionServices {
    constructor() {
        this.AxiosClient = new AxiosClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.ANONYMOUSQUESTION}?$expand=Major`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.ANONYMOUSQUESTION}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.ANONYMOUSQUESTION}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.ANONYMOUSQUESTION}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.ANONYMOUSQUESTION}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default anonymousQuestionServices;




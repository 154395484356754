import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle'; // Adjust the import path as needed
import '../form.css'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import YearService from "../../../services/year";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';

interface YearData {
    Year: string;
}

const CreateYearPage: React.FC = () => {
    const [formData, setFormData] = useState<YearData>({ Year: '' });
    const [error, setError] = useState<string | null>(null);

    const yearsService = new YearService();
    const navigate = useNavigate();
    const handleYearChange = (date: Date | null) => {
        if (date) {
            const year = date.getFullYear().toString();
            setFormData({ Year: year });
        } else {
            setFormData({ Year: '' });
        }
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            console.log("Submitting data:");
            const data = await yearsService.getAll();
            if (data && data.value && Array.isArray(data.value)) {
                const existingYear = data.value.find((year: { Year: number, IsDelete: boolean }) =>
                    year.Year.toString() === formData.Year && !year.IsDelete
                );
                if (existingYear) {
                    setError("Year already exists.");
                    console.log("Data submission failed: Year already exists.");
                    return;
                }
                const response = await yearsService.create(formData);
                console.log("Data submitted successfully:", response);
                navigate("/year");
            } else {
                console.error("Failed to fetch existing years or no years found.");
            }
        } catch (error) {
            setError("Please select year.");
            console.error("Error during submission:", error);
        }
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Year', path: '/year' },
                    { label: 'Create Year', path: '/year/create', active: true },
                ]}
                title=""
            />
            <Row>
                <Col md={6} className="mx-auto">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                            <div className="page-title-box">
                                    <h2 className="page-title">CREATE Year</h2>
                                </div>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Year</Form.Label>
                                    <DatePicker
                                        selected={formData.Year ? new Date(parseInt(formData.Year), 0, 1) : null}
                                        onChange={handleYearChange}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        className="form-control "
                                        placeholderText="Select Year"
                                        yearItemNumber={9}
                                    />
                                </Form.Group>
                                {error && <div className="alert alert-danger">{error}</div>}
                                <div className="d-flex justify-content-center mt-3">
                                    <Button variant="success" type="submit">
                                    <i className="fe-check-circle me-1"></i>Create
                                    </Button>
                                    <Link
                                        to="/year"
                                        className="btn btn-danger waves-effect waves-light ms-2"  // Thêm ms-2 để tạo khoảng cách giữa các nút
                                    >
                                        <i className="mdi mdi-backspace me-1"></i> Back
                                    </Link>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CreateYearPage;

import React, { useState, useEffect } from 'react';
import { Table, Form, FormControl, Pagination, DropdownButton, Dropdown, Button, Container, Row, Col, Card } from 'react-bootstrap';

interface TypeScore {
  name: string;
  grade: number;
}

interface Score {
  ProfileName: any;
  userId: string;
  scoreId: string;
  typeScore: TypeScore[];
}

interface ScoreTableProps {
  data: {
    scores: Score[];
  };
  onHandleBlockchain: () => void;
  isScore: any;
  hash: any;
}

const ScoreTable: React.FC<ScoreTableProps> = ({ data, onHandleBlockchain, isScore, hash }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredScores, setFilteredScores] = useState<Score[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (data && data.scores) {
      const filtered = data.scores.filter(score =>
        score.ProfileName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredScores(filtered);
    }
  }, [searchTerm, data]);

  const handleSelectPage = (number: number) => {
    setCurrentPage(number);
  };

  const handlePageSizeChange = (eventKey: string | null) => {
    const newSize = Number(eventKey);
    if (!isNaN(newSize)) {
      setPageSize(newSize);
      setCurrentPage(1); // Reset to the first page
    }
  };

  if (!data || !data.scores || data.scores.length === 0) {
    return <div>No scores available</div>;
  }

  if (filteredScores.length === 0) {
    return <div>No scores available</div>; // "No scores available for this class"
  }

  const columns = data.scores?.flatMap(score => score.typeScore.map(ts => ts.name));
  const uniqueColumns = Array.from(new Set(columns)).sort();

  return (
    <>
      <div className='page-title-box mb-3 d-flex justify-content-between align-items-center'>
        <h2 className="page-title">Scores in Class Management</h2>
        {hash ? (
          <div className="d-flex align-items-center">
            <span className="me-2">Hash: {hash}</span>
          </div>
        ) :
          (
            <Button onClick={onHandleBlockchain} className="ml-3">Upload To BlockChain</Button>
          )}
      </div>
      <div className="table-responsive" style={{ overflowX: "hidden" }}>

        <Row>
          <Col >
            <Card>
              <Card.Body >
                <Form className="mb-3 d-flex justify-content-between align-items-center">
                  <div className='d-flex align-items-center'>
                    <span className='mr-2 me-2'>Search: {" "}</span>
                    <FormControl
                      type="text"
                      placeholder="Search by..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ maxWidth: '300px' }}
                    />
                  </div>
                </Form>
                <Table className='table table-centered react-table'>
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      {uniqueColumns.map(column => (
                        <th key={column}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredScores.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((score) => (
                      <tr key={score.scoreId}>
                        <td>{score.ProfileName || "Unknown"}</td>
                        {uniqueColumns.map(column => {
                          const typeScore = score.typeScore.find(ts => ts.name === column);
                          return (
                            <td key={`${score.scoreId}-${column}`}>
                              {typeScore ? typeScore.grade : 'N/A'}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex align-items-center">
                    <span className='mr-2 me-2'>Display : {" "}</span>
                    <DropdownButton
                      title={pageSize}
                      onSelect={handlePageSizeChange}
                      variant="outline-secondary"
                      style={{ backgroundColor: "white" }}
                    >
                      {[5, 10, 20, 50].map(size => (
                        <Dropdown.Item key={size} eventKey={String(size)}>
                          {size}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <Pagination className="mb-0">
                    {Array.from({ length: Math.ceil(filteredScores.length / pageSize) }, (_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handleSelectPage(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                  </Pagination>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

    </>
  );
};

export default ScoreTable;

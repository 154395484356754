import { useState, useEffect } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import departmentServices from '../../../services/department';
import '../form.css';

export function FormRender({ formData, setFormData }) {
  const [departments, setDepartments] = useState([]);
  const [selectedYear, setSelectedYear] = useState(""); // State to track the selected year label
  const departmentService = new departmentServices();

  const fetchdepartments = async () => {
    const data = await departmentService.getAllAdd();
    if (typeof data === 'string') {
      setDepartments([]);
    } else {
      setDepartments(data.value);
    }
  };

  useEffect(() => {
    fetchdepartments();
  }, []);

  useEffect(() => {
    if (formData?.DepartmentId && departments.length > 0) {
      const yearLabel = departments.find(year => Number(year.Id) === Number(formData.DepartmentId))?.DepartmentName;
      if (yearLabel) {
        setSelectedYear(yearLabel);
      } else {
        console.error("Year label not found for ID:", formData.YearId);
      }
    }
  }, [formData, departments]);
  
  
  const yearOptions = departments.map((year) => ({
    label: year.DepartmentName,
    value: year.Id,
  }));

  const handleSelectYear = (eventKey, event) => {
    const selectedLabel = departments.find(year => year.Id.toString() === eventKey)?.DepartmentName.toString();
    setSelectedYear(selectedLabel);
    setFormData({
      ...formData,
      DepartmentId: eventKey
    });
  };


  return (
    <div className="p-fluid">
      <Form.Group className="mb-3">
        <Form.Label htmlFor="department">Department</Form.Label>
        <Dropdown onSelect={handleSelectYear}>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic"
            value={formData?.DepartmentId || ""}
            onChange={(e) => setFormData({ ...formData, DepartmentId: e.value })}
          >
            {selectedYear || "Select Department"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {yearOptions.map(option => (
              <Dropdown.Item key={option.value} eventKey={option.value}>
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="majorCode">Major Code</Form.Label>
        <Form.Control
          type="text"
          id="majorCode"
          value={formData?.MajorCode || ""}
          onChange={e => setFormData({ ...formData, MajorCode: e.target.value })}
          placeholder="Major Code"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="majorName">Major Name</Form.Label>
        <Form.Control
          type="text"
          id="majorName"
          value={formData?.MajorName || ""}
          onChange={e => setFormData({ ...formData, MajorName: e.target.value })}
          placeholder="Major Name"
        />
      </Form.Group>
    </div>
  );
}

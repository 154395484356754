import { Form, Dropdown } from 'react-bootstrap';

export function FormRender({ formData, setFormData }) {

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="directoryType">Name Type</Form.Label>
        <Form.Control
          type="text"
          id="DirectoryName"
          value={formData?.DirectoryName || ""}
          onChange={e => setFormData({ ...formData, DirectoryName: e.target.value })}
          placeholder="Enter Directory Name"
        />
      </Form.Group>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle';
import YearServices from '../../../services/year';
import { Typeahead } from 'react-bootstrap-typeahead';
import '../form.css';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';

interface Year {
    Id: number;
    Year: string;
}

interface Term {
    TermCode: string;
    TermName: string;
}
interface YearData {
    Year: string;
}
const schema = yup.object().shape({
    YearId: yup.number().required("Academic year is required"),
    terms: yup.array().of(
        yup.object().shape({
            TermCode: yup.string().required("Term code is required"),
            TermName: yup.string().required("Term name is required"),
        })
    ),
});

const CreateTermPage: React.FC = () => {
    const [years, setYears] = useState<Year[]>([]);
    const [selectedYear, setSelectedYear] = useState<Year | null>(null);
    const [error, setErrorYear] = useState<string | null>(null);
    const [addYearDisplay, setAddYearDisplay] = useState<string>("none");
    const [addMd, setMd] = useState<number>(12);

    const navigate = useNavigate();
    const { register, control, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            terms: [{ TermCode: '', TermName: '' }]  // Set initial term here
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "terms"
    });

    const yearService = new YearServices();

    const fetchYears = async () => {
        try {
            const response = await yearService.getAll();

            if (Array.isArray(response.value)) {
                const yearsWithStringLabel = response.value.map((year: Year) => ({
                    ...year,
                    Year: year.Year.toString()
                }));
                setYears(yearsWithStringLabel);
            }
        }
        catch (error) {
            console.error("Error fetching years:", error);
        }
    };

    useEffect(() => {
        fetchYears();
    }, []);

    const handleSelectYear = (selected: Year[]) => {
        const year = selected.length > 0 ? selected[0] : null;
        setSelectedYear(year);
        if (year) {
            setValue('YearId', year.Id); // Assuming YearId is directly a field in the form
            clearErrors('YearId'); // Correctly clear errors
        } else {
            setError('YearId', { type: 'manual', message: 'Academic year is required' }); // Correctly set errors
        }
    };

    const onSubmit = async (data: any) => {
        try {
            console.log("token", sessionStorage.getItem('token'));
            const token = sessionStorage.getItem('token');
            const response = await fetch('https://crud.live:447/blms/add-list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data.terms.map((term: any) => ({
                    yearId: data.YearId,
                    termName: term.TermName,
                    termCode: term.TermCode
                })))
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.status}`);
            }
            window.location.assign("/term");
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const [formData, setFormData] = useState<YearData>({ Year: '' });
    const handleYearChange = (date: Date | null) => {
        if (date) {
            const year = date.getFullYear().toString();
            setFormData({ Year: year });
        } else {
            setFormData({ Year: '' });
        }
    };
    const handleSubmitYear = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            console.log("Submitting data:");
            const data = await yearService.getAll();
            if (data && data.value && Array.isArray(data.value)) {
                const existingYear = data.value.find((year: { Year: number, IsDelete: boolean }) =>
                    year.Year.toString() === formData.Year && !year.IsDelete
                );
                if (existingYear) {
                    setErrorYear("Year already exists.");
                    console.log("Data submission failed: Year already exists.");
                    return;
                }
                const response = await yearService.create(formData);
                console.log("Data submitted successfully:", response);
                fetchYears();
            } else {
                console.error("Failed to fetch existing years or no years found.");
            }
        } catch (error) {
            setErrorYear("Please select year.");
            console.error("Error during submission:", error);
        }
    };
    return (
        <>
           <PageTitle
                breadCrumbItems={[
                    { label: 'Term', path: '/term' },
                    { label: 'Create Term', path: '/term/create', active: true },
                ]}
                title="CREATE TERM"
            />
            <Button className='btn btn-success waves-effect waves-light ms-1 mb-3' onClick={() => { setAddYearDisplay("block"); setMd(6) }}>Add New Year</Button>
            <Row>
                <Col md={addMd} className='mx-auto' >
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>

                                <Form.Group className="mb-3" style={{ width: "20%" }}>
                                    <Form.Label>Year</Form.Label>
                                    <Typeahead
                                        id="year"
                                        onChange={handleSelectYear}
                                        options={years}
                                        labelKey="Year"
                                        selected={selectedYear ? [selectedYear] : []}
                                        placeholder="Select Year"
                                        clearButton
                                    />
                                </Form.Group>
                                {errors.YearId && <p className="text-danger">{errors.YearId.message}</p>}

                                {fields.map((field, index) => (
                                    <div className="term-row" key={field.id}>
                                        <div className="term-input">
                                            <Form.Group className="mb-2 me-1">
                                                <Form.Label>Term Code</Form.Label>
                                                <Form.Control
                                                    {...register(`terms.${index}.TermCode`)}
                                                    placeholder="Enter Term Code"
                                                />
                                                <div className="text-danger">
                                                    {errors.terms?.[index]?.TermCode?.message}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="term-input">
                                            <Form.Group className="mb-2 ms-1">
                                                <Form.Label>Term Name</Form.Label>
                                                <Form.Control
                                                    {...register(`terms.${index}.TermName`)}
                                                    placeholder="Enter Term Name"
                                                />
                                                <div className="text-danger">
                                                    {errors.terms?.[index]?.TermName?.message}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        {index > 0 ? (
                                            <Button
                                                style={{ background: "none", color: "black", border: "none" }}
                                                className="mdi mdi-trash-can"
                                                onClick={() => remove(index)}
                                            />
                                        ) :
                                            <div style={{ width: "40px" }}></div>}
                                    </div>
                                ))}
                                <Row className="mt-2">
                                    <Col className="text-center">
                                        <Button variant="secondary" onClick={() => append({ TermCode: '', TermName: '' })} className="mt-3">
                                            <i className="fe-plus-circle"></i>
                                        </Button>
                                        <div className=" mt-3">
                                            <Button variant="success" type="submit">
                                                <i className="fe-check-circle me-1"></i> Create
                                            </Button>
                                            <Link
                                                to="/term"
                                                className="btn btn-danger waves-effect waves-light ms-2"  // Thêm ms-2 để tạo khoảng cách giữa các nút
                                            >
                                                <i className="fe-x me-1"></i> Cancel
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} style={{ display: `${addYearDisplay}` }} >
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmitYear}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Year</Form.Label>
                                    <ReactDatePicker
                                        selected={formData.Year ? new Date(parseInt(formData.Year), 0, 1) : null}
                                        onChange={handleYearChange}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        className="form-control "
                                        placeholderText="Select Year"
                                        yearItemNumber={9}
                                    />
                                </Form.Group>
                                {error && <div className="alert alert-danger">{error}</div>}
                                <div className="d-flex justify-content-center mt-3">
                                    <Button variant="success" type="submit">
                                        <i className="fe-check-circle me-1"></i>Create
                                    </Button>
                                    <Button className="btn btn-danger waves-effect waves-light ms-1" onClick={() => { setAddYearDisplay("none"); setMd(12) }}>
                                        <i className="fe-x me-1"></i> Cancel
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* <PageTitle
                breadCrumbItems={[
                    { label: 'Term', path: '/term' },
                    { label: 'Create Term', path: '/term/create', active: true },
                ]}
                title="CREATE TERM"
            /> */}
            <Row>

            </Row>
        </>
    )
};

export default CreateTermPage;
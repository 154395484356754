import React, { useState, useEffect } from 'react';
import { Form, Dropdown, Button, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle'; // Adjust the import path as needed
import DepartmentServices from '../../../services/department'; // Adjust the import path as needed
import '../form.css'; // Adjust the import path as needed
import { Link, useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead'; // Ensure this is correctly imported

interface department {
    Id: number;
    DepartmentName: string;
}

interface Major {
    MajorCode: string;
    MajorName: string;
}

interface MajorData {
    DepartmentId?: number;
    majors: Major[];
}
interface ErrorState {
    department?: string;
    majors: { [key: number]: { MajorCode?: string; MajorName?: string } };
}
const CreateMajorPage: React.FC = () => {
    const [departments, setdepartments] = useState<department[]>([]);
    const [selecteddepartment, setSelecteddepartment] = useState<string>('');
    const [formData, setFormData] = useState<MajorData>({ majors: [{ MajorCode: '', MajorName: '' }] });
    const departmentService = new DepartmentServices();
    const navigation = useNavigate();
    const [errors, setErrors] = useState<ErrorState>({ majors: {} });

    const fetchdepartments = async () => {
        const data = await departmentService.getAllAdd();
        setdepartments(typeof data === 'string' ? [] : data.value);
    };

    useEffect(() => {
        fetchdepartments();
    }, []);

    const handleSelectdepartment = (eventKey: string | null) => {
        const department = departments.find(d => d.Id.toString() === eventKey);
        if (department) {
            setSelecteddepartment(department.DepartmentName);
            setFormData(prev => ({
                ...prev,
                DepartmentId: department.Id
            }));
        }
    };

    const addmajor = () => {
        setFormData(prev => ({
            ...prev,
            majors: [...prev.majors, { MajorCode: '', MajorName: '' }]
        }));
    };

    const updatemajor = (index: number, key: keyof Major, value: string) => {
        const updatedmajors = formData.majors.map((major, i) =>
            i === index ? { ...major, [key]: value } : major
        );
        setFormData({ ...formData, majors: updatedmajors });
    };

    const removemajor = (index: number) => {
        const updatedmajors = formData.majors.filter((_, i) => i !== index);
        setFormData({ ...formData, majors: updatedmajors });
    };
    const validateForm = () => {
        const newErrors: ErrorState = { majors: {} };
        let isValid = true;
    
        // Validate department selection
        if (!formData.DepartmentId) {
            newErrors.department = "Please select a department.";
            isValid = false;
        }
    
        // Validate each major
        formData.majors.forEach((major, index) => {
            if (!major.MajorCode.trim()) {
                newErrors.majors[index] = {...newErrors.majors[index], MajorCode: "Major Code is required."};
                isValid = false;
            }
            if (!major.MajorName.trim()) {
                newErrors.majors[index] = {...newErrors.majors[index], MajorName: "Major Name is required."};
                isValid = false;
            }
        });
    
        setErrors(newErrors);
        return isValid;
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) return;
        try {
            const majorsToSubmit = formData.majors.map(major => ({
                departmentId: formData.DepartmentId,
                majorName: major.MajorName,
                majorCode: major.MajorCode
            }));
            console.log("Submitting:", JSON.stringify(majorsToSubmit)); // Debugging

            const response = await fetch('https://crud.live:447/major/add-list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(majorsToSubmit)
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.status}`);
            }
            navigation('/major')

            const text = await response.text();
            let jsonData = {};
            try {
                jsonData = JSON.parse(text);
            } catch (error) {
                console.warn('No JSON to parse or invalid JSON:', text);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Major', path: '/major' },
                    { label: 'Create Major', path: '/major/create', active: true },
                ]}
                title=""
            />
            <Row>
                <Col md={8} className='mx-auto'>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <div className="page-title-box">
                                    <h2 className="page-title">CREATE Major</h2>
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Department</Form.Label>
                                    <Dropdown onSelect={handleSelectdepartment}>
                                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-department">
                                            {selecteddepartment || "Select department"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {departments.map(department => (
                                                <Dropdown.Item key={department.Id} eventKey={department.Id.toString()}>
                                                    {department.DepartmentName}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                                {errors.department && <div className="alert alert-danger">{errors.department}</div>}
                                {formData.majors.map((major, index) => (
                                    <div className="term-row" key={index}>
                                        <div className="term-input">
                                            <Form.Group className="mb-2 me-5">
                                                <Form.Label>Major Code</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Code"
                                                    value={major.MajorCode}
                                                    onChange={e => updatemajor(index, 'MajorCode', e.target.value)}
                                                />
                                            </Form.Group>
                                            {errors.majors[index]?.MajorCode && <div className="alert alert-danger me-5">{errors.majors[index].MajorCode}</div>}
                                        </div>

                                        <div className="term-input">
                                            <Form.Group className="mb-2 me-5">
                                                <Form.Label>Major Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    value={major.MajorName}
                                                    onChange={e => updatemajor(index, 'MajorName', e.target.value)}
                                                />
                                            </Form.Group>
                                            {errors.majors[index]?.MajorName && <div className="alert alert-danger me-5">{errors.majors[index].MajorName}</div>}

                                        </div>

                                        {index > 0 ?
                                            <Button
                                                style={{ background: "none", color: "black", border: "none" }}
                                                className="mdi mdi-trash-can"
                                                onClick={() => removemajor(index)}
                                            /> : <div style={{width: "2.5rem"}}></div>
                                        }
                                    </div>
                                ))}

                                <Row className="mt-2">
                                    <Col className="text-center">
                                        <Button variant="secondary" onClick={addmajor} className="mt-3">
                                            <i className="fe-plus-circle"></i>
                                        </Button>
                                        <div className=" mt-3">
                                            <Button variant="success" type="submit">
                                                <i className="fe-check-circle me-1"></i> Create
                                            </Button>
                                            <Link
                                                to="/term"
                                                className="btn btn-danger waves-effect waves-light ms-1"
                                            >
                                                <i className="fe-x me-1"></i> Cancel
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CreateMajorPage;

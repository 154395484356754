import { useState, useEffect, useRef } from "react";
import classServices from "../../../services/class";
import TableCard from "../../../../apps/Card/TableCard";

function ClassManagement() {
  const [classes, setClasses] = useState([]);
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  

  const classService = new classServices();

  const fetchClasses = async () => {
    setLoading(true);
    const data = await classService.getAll();
    if (typeof data === "string") {
      setClasses([]);
      console.error("Failed to fetch data:", data);
    } else {
      setClasses(data.value);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchClasses();
  }, []);


  return (
    <div>
      <TableCard
       label="Class"
       data={classes}
       fetchData={fetchClasses}
      />
    </div>
  );
}

export default ClassManagement;

import { useState, useEffect } from "react";
import Advanced from "../../../../tables/Advanced";
import { accountColumns, profileColumns } from "../data";
import 'react-toastify/dist/ReactToastify.css';
import '../form.css';
import AccountService from "../../../services/account";
import ProfileServices from "../../../services/profile";
import FileUploader from "../../../../../components/FileUploader";
import FileUpload from "../../../../forms/FileUpload";
import { Modal, Button, Form } from "react-bootstrap";
import MajorServices from "../../../services/major";
import { Typeahead } from "react-bootstrap-typeahead";
import PageTitle from "../../../../../components/PageTitle";
import FormAccountLecture from "./formStudent";
import { withSwal } from 'react-sweetalert2';
import TermServices from "../../../services/term"; // Import the Term service

const StudentAccount = withSwal(({ swal }) => {

    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState([]);
    const [selectedAccountId, setselectedAccountId] = useState(null);
    const [profile, setProfile] = useState([]);
    const [showImportModal, setShowImportModal] = useState(false);
    const [data, setData] = useState([]);
    const [major, setMajor] = useState([]);
    const [selectedMajorId, setSelectedMajorId] = useState(null);
    const [termId, setTermId] = useState(''); // State for selected term ID
    const [terms, setTerms] = useState([]); // State to store fetched terms

    const apiMajor = new MajorServices();
    const api = new AccountService();
    const apiProfile = new ProfileServices();
    const apiTerm = new TermServices(); // Initialize the Term service

    const fetchData = async () => {
        setLoading(true);
        const majorData = await apiMajor.getAll();
        const termData = await apiTerm.getAll(); // Fetch terms
        const data = await api.getAllAccountStudent();
        console.log(data);
        setAccount(data.result);
        setMajor(majorData.value);
        setTerms(termData.value);
        setLoading(false);
    };

    const handleData = async (userId) => {
        try {
            const answersData = await apiProfile.getId(userId);
            if (answersData && answersData.result) {
                setProfile([answersData.result]);
                setselectedAccountId(userId);
                console.log("Profile fetched successfully:", answersData.result);
            } else {
                console.log("No data received from API");
            }
        } catch (error) {
            console.error("Failed to fetch profile data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdate = async (data) => {
        console.log("Submitting data:", data);
        await apiProfile.update(data);
        const userTerm = {
            userId: data.userId,
            termId: data.termId,
            isCurrent: true,
        };
        const responseUpdateTerm = await fetch('https://crud.live:447/blms/UserTerm', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(userTerm)
        });
        console.log("Update data:", responseUpdateTerm);

        fetchData();
        handleData(data.userId);
    };

    const handleBanToggle = async (id) => {
        try {
            const answersData = await api.getDetails(id);
            let response;
            console.log("Account unbanned successfully:", answersData.result);

            if (!answersData.result.isBanned) {
                response = await api.ban(id);
                console.log("Account banned successfully:", id);
            } else {
                response = await api.unBan(id);
                fetchData();
                console.log("Account unbanned successfully:", id);
            }

        } catch (error) {
            console.error("Error handling the ban/unban operation:", error);
        }
    };

    const formRender = ({ formData, setFormData } = {}) => {
        return (
            <FormAccountLecture formData={formData} setFormData={setFormData} />
        );
    };

    const onImport = () => {
        setShowImportModal(true);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setData(file);
    };

    const handleResetPass = async (id) => {
        swal.fire({
            title: 'Reset Password?',
            text: "This will reset the password for the account.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#66bb6a',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, reset it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const profile = await apiProfile.getId(id);
                const response = await fetch('https://crud.live:446/api/authentication/reset-password', {
                    method: 'POST',
                    body: JSON.stringify({ email: profile.email })
                });
                console.log("Resetting password for account:", id);
                swal.fire(
                    'Reset!',
                    'The password has been reset successfully.',
                    'success'
                );
            }
        });
    };

    const handleSubmitList = async () => {
        if (!data) {
            console.error("No file selected.");
            return;
        }
        try {
            const formData = new FormData();
            formData.append("file", data);
            formData.append("majorId", selectedMajorId[0].Id);
            const selectedMajorCode = major.find(m => m.Id === selectedMajorId[0].Id)?.MajorCode;
            formData.append("prefixCode", selectedMajorCode);
            formData.append("termId", termId); // Append termId to formData
            console.log(selectedMajorId[0].Id);

            const response = await fetch('https://crud.live:446/api/AccountManagement/import-accounts', {
                method: 'POST',
                body: formData,
            });
            console.log(formData);
            if (response.ok) {
                console.log('File uploaded successfully');
                fetchData(); // Reload data after upload
                setShowImportModal(false); // Close the modal on success
            } else {
                throw new Error('Failed to upload file. Status: ' + response.status);
            }
        } catch (error) {
            console.error("Failed to submit account list:", error);
        }
    };

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Advanced
                        label="Student"
                        title="Student Account Management"
                        columns={accountColumns}
                        data={account}
                        isSelect={true}
                        isEdit={false}
                        link="/add-account"
                        formRender={formRender}
                        onUpdate={handleUpdate}
                        onDelete={handleBanToggle}
                        onRestore={handleBanToggle}
                        moreData={handleData}
                        fetchData={fetchData}
                        isDelete={false}
                        buttonTitle="Profile"
                        isImport={true}
                        onImport={onImport}
                        isResetPass={true}
                        onResetPass={handleResetPass}
                    />
                    {selectedAccountId &&
                        (
                            <Advanced
                                title={`Profile of: ${profile[0]?.lastName} ${profile[0]?.firstName}`}
                                columns={profileColumns}
                                data={profile}
                                isAdd={false}
                                isSearch={false}
                                canDelete={false}
                                formRender={formRender}
                                onUpdate={handleUpdate}
                                onDelete={handleBanToggle}
                            />
                        )}
                </>
            )}
            <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Import Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Typeahead
                            id="basic-typeahead-single"
                            labelKey="MajorName"
                            onChange={setSelectedMajorId}
                            options={major}
                            placeholder="Choose a major..."
                        />

                        <Form.Group controlId="termId" className="mt-3">
                            <Form.Label>Term</Form.Label>
                            <Form.Control
                                as="select"
                                value={termId}
                                onChange={(e) => setTermId(e.target.value)}
                            >
                                <option value="">Select Term</option>
                                {terms.map(term => (
                                    <option key={term.Id} value={term.Id}>
                                        {term.TermName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <div className="mt-5">
                            <input type="file" onChange={handleFileUpload} accept=".xlsx, .xls" />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowImportModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleSubmitList}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
});

export default StudentAccount;

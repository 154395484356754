import FetchClient from "../utils/axios";
import { API } from "../utils/api";


class FileServices {
    constructor() {
        this.AxiosClient = new FetchClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.FILE}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.FILE}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.FILE}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.FILE}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.FILE}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default FileServices;
import { useState, useEffect, useRef } from "react";
import Advanced from "../../../../tables/Advanced";
import { yearsColumns } from "../data";
import YearService from "../../../services/year";
import { FormRender } from "./yearForm";
function YearManagement() {
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(true);

  const yearsService = new YearService();


  const fetchYears = async () => {
    setLoading(true);
    const data = await yearsService.getAll();
    if (typeof data === "string") {
      setYears([]);
      console.error("Failed to fetch data:", data);

    } else {
      setYears(data.value);
      console.log("fetch data:", data);

    }
    setLoading(false);
  };

  useEffect(() => {
    fetchYears();
  }, []);

  const handleSubmit = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await yearsService.create(data);
    console.log("Data submitted successfully:", response);
    fetchYears();
  };

 
  const handleUpdate = async (data) => {
    try {
      const data1 = await yearsService.getAll();
      if (data1 && data1.value && Array.isArray(data1.value)) {
        const existingYear = data1.value.find((year) =>
          year.Year.toString() === data.Year && !year.IsDelete
        );
        if (existingYear) {
          console.log("Data submission failed: Year already exists.");
          return;
        }
        const response = await yearsService.update(data.Id, data);
        console.log("Data submitted successfully:", response);
        fetchYears();
      } else {
        console.error("Failed to fetch existing years or no years found.");
      }
    } catch (error) {
      console.error("Error during data submission:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await yearsService.delete(id);
      console.log("Data deleted successfully:", response);
      fetchYears(); // Refresh the list after deletion
    } catch (error) {
      console.error("Failed to delete data:", error);
    }
  };

  function formRender({ formData, setFormData } = {}) {
    return (
      <FormRender formData={formData} setFormData={setFormData} />
    )
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Advanced
          label="Year"
          link="/year/create"
          title="YEAR MANAGEMENT"
          columns={yearsColumns}
          data={years}
          isAdd={false}
          formRender={formRender}
          onSubmit={handleSubmit}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fetchData={fetchYears}

        />
      )}
    </div>
  );
}

export default YearManagement;

import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import DirectoryServices from '../../../services/directory'

export function FormRenderDirectory({ formData, setFormData } : any) {
  const [type, setType] = useState([])
    
  const fetchData = async () => {
    const api = new DirectoryServices()
    const data = await api.getAll()
    setType(data.value)
  }
    
  useEffect(() => {
    fetchData()
  }, [])
    
  const handleChange = (e:any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="DirectoryTypeId">Directory Type</Form.Label>
        <Form.Select
          id="DirectoryTypeId"
          name="DirectoryTypeId"
          value={formData?.DirectoryTypeId || ""}
          onChange={handleChange}
          placeholder="Enter Directory Type"
        >
          <option value="">Select Directory Type</option>
          {type.map((maj:any) => (
            <option key={maj.Id} value={maj.Id}>
              {maj.DirectoryName}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </div>
  )
}

import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { username: string; password: string }) {
  const baseUrl = "http://103.216.118.120:5002/api/authentication/login";
  console.log("login api");
  
  console.log(params);
  console.log(baseUrl);
  
  const loginParams = {
    userName: params.username,
    password: params.password,
  };

  return api.create(`${baseUrl}`, loginParams);
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };

import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle'; // Adjust the import path as needed
import '../form.css'; // Adjust the import path as needed
import { Link, useNavigate } from 'react-router-dom';
import DepartmentService from "../../../services/department";

interface departmentData {
    DepartmentName: string;
}

const CreatedepartmentPage: React.FC = () => {
    const [formData, setFormData] = useState<departmentData>({ DepartmentName: '' });
    const navigation = useNavigate();
    const [error, setError] = useState<string | null>(null);

    const departmentsService = new DepartmentService();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        if (!formData.DepartmentName.trim()) {
            setError("Department Name is required.");
            return;  // Stop the submission if the field is empty
        }
    
        console.log("Submitting data:");
        try {
            const response = await departmentsService.create(formData);
            console.log("Data submitted successfully:", response);
            navigation('/department');  // Navigate on successful creation
        } catch (error) {
            console.error("Error submitting department:", error);
            setError("An error occurred while creating the department.");
        }

    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Department', path: '/department' },
                    { label: 'Create department', path: '/department/create', active: true },
                ]}
                title="Create Department"
            />
            <Row>
                <Col md={10} className="mx-auto">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                               
                                <Form.Group className="mb-3">
                                    <Form.Label>Department Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Department Name"
                                        value={formData.DepartmentName}
                                        onChange={e => setFormData({ ...formData, DepartmentName: e.target.value })}
                                    />
                                </Form.Group>
                                {error && <div className="alert alert-danger">{error}</div>}
                                <div className="d-flex justify-content-center mt-3">
                                    <Button variant="success" type="submit">
                                        <i className="fe-check-circle me-1"></i> Create
                                    </Button>
                                    <Link
                                        to="/department"
                                        className="btn btn-danger waves-effect waves-light ms-1"
                                    >
                                        <i className="fe-x me-1"></i> Cancel
                                    </Link>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
export default CreatedepartmentPage;
import { useState, useEffect } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import categoryServices from '../../../services/category';
import '../form.css';

export function FormRender({ formData, setFormData }) {
  const [categorys, setcategorys] = useState([]);
  const [selectedcategory, setSelectedcategory] = useState(""); // State to track the selected category label
  const categoryService = new categoryServices();

  const fetchcategorys = async () => {
    const data = await categoryService.getAll();
    if (typeof data === 'string') {
      setcategorys([]);
    } else {
      setcategorys(data.value);
    }
  };

  useEffect(() => {
    fetchcategorys();
  }, []);

  useEffect(() => {
    if (formData?.CategoryId && categorys.length > 0) {
      const categoryLabel = categorys.find(category => Number(category.Id) === Number(formData.CategoryId))?.CategoryName;
      if (categoryLabel) {
        setSelectedcategory(categoryLabel);
      } else {
        console.error("category label not found for ID:", formData.CategoryId);
      }
    }
  }, [formData, categorys]);
  
  
  const categoryOptions = categorys.map((category) => ({
    label: category.CategoryName,
    value: category.Id,
  }));

  const handleSelectCategory = (eventKey) => {
    const selectedLabel = categorys.find(category => category.Id === eventKey)?.CategoryName.toString();
    setSelectedcategory(selectedLabel);
    setFormData({
      ...formData,
      CategoryId: eventKey
    });
  };

  return (
    <div className="p-fluid">
      <Form.Group className="mb-3">
        <Form.Label htmlFor="category">Category</Form.Label>
        <Dropdown onSelect={handleSelectCategory}>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic"
            value={formData?.CategoryId || ""}
            onChange={(e) => setFormData({ ...formData, CategoryId: e.value })}
          >
            {selectedcategory || "Select category"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {categoryOptions.map(option => (
              <Dropdown.Item key={option.value} eventKey={option.value}>
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="majorCode">Course Code</Form.Label>
        <Form.Control
          type="text"
          id="majorCode"
          value={formData?.CourseCode || ""}
          onChange={e => setFormData({ ...formData, CourseCode: e.target.value })}
          placeholder="Major Code"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="majorName">Course Name</Form.Label>
        <Form.Control
          type="text"
          id="majorName"
          value={formData?.CourseName || ""}
          onChange={e => setFormData({ ...formData, CourseName: e.target.value })}
          placeholder="Major Name"
        />
      </Form.Group>
    </div>
  );
}

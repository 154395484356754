
import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle'; // Adjust the import path as needed
import '../form.css'; // Adjust the import path as needed
import { Link, useNavigate } from 'react-router-dom';
import directoryTypeService from "../../../services/directory";

interface directoryTypeData {
    DirectoryName: string;
}

const CreatedirectoryTypePage: React.FC = () => {
    const [formData, setFormData] = useState<directoryTypeData>({ DirectoryName: '' });
    const navigation = useNavigate();

    const directoryTypesService = new directoryTypeService();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("Submitting data:");
        const response = await directoryTypesService.create(formData);
        console.log("Data submitted successfully:", response);
        navigation('/directory')
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'DirectoryType', path: '/directory' },
                    { label: 'Create Directory Type', path: '/directory/create', active: true },
                ]}
                title=""
            />
            <Row>
                <Col md={6} className="mx-auto">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <div className="page-title-box">
                                    <h2 className="page-title">Create Directory Type</h2>
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Directory Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Type Name"
                                        value={formData.DirectoryName}
                                        onChange={e => setFormData({ ...formData, DirectoryName: e.target.value })}
                                    />
                                </Form.Group>
                                <Row className="mt-2">
                                    <Col className="text-center">
                                       
                                        <div className=" mt-3">
                                            <Button variant="success" type="submit">
                                                <i className="fe-check-circle me-1"></i> Create
                                            </Button>
                                            <Link
                                                to="/term"
                                                className="btn btn-danger waves-effect waves-light ms-1"
                                            >
                                                <i className="fe-x me-1"></i> Cancel
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CreatedirectoryTypePage;

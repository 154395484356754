import { useState, useEffect, useRef } from "react";
import Advanced from "../../../../tables/Advanced";
import { majorColumns } from "../data";
import majorServices from "../../../services/major";
import { FormRender } from "./formrender";
function MajorManagement() {
  const [majors, setmajors] = useState([]);
  const [loading, setLoading] = useState(true);
 
  const majorService = new majorServices();


  const fetchmajors = async () => {
    setLoading(true);
    const data = await majorService.getAll();
    console.log("-------------------------------------log data");
    console.log(data);
    if (typeof data === "string") {
      setmajors([]);
      console.error("Failed to fetch data:", data);
    } else {
      setmajors(data.value);
      
    }
    setLoading(false);
  };


  const handleUpdate = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await majorService.update(data.Id, data);
    console.log("Data submitted successfully:", response);
    fetchmajors();
  };

  const handleDelete = async (id) => {
  
      try {
        const response = await majorService.delete(id);
        console.log("Data deleted successfully:", response);
        fetchmajors();
      } catch (error) {
        console.error("Failed to delete data:", error);
      }
    
  };
  
  function formRender({ formData, setFormData } = {}) {
    return (
      <FormRender formData={formData} setFormData={setFormData} />
    )
  }
  useEffect(() => {
    fetchmajors();
  }, []);


  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Advanced
          label="Major"
          title="Major MANAGEMENT"
          link="/major/create"
          columns={majorColumns}
          data={majors}
          formRender={formRender}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fetchData={fetchmajors}
        />
      )}
    </div>
  );
}

export default MajorManagement;

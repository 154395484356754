const APIHOST = `https://crud.live:446/`;

class FetchClient {
  constructor() {
    const token = sessionStorage.getItem('token') || "";
    this.headers = {
      "Content-Type": "application/json",
      "Authorization": token ? `Bearer ${token}` : "",
    };
  }

  async request(method, endpoint, data = null) {
    const options = {
      method: method,
      headers: this.headers,
      body: data ? JSON.stringify(data) : null,
    };

    // For GET and DELETE requests, remove the body property
    if (method.toLowerCase() === 'get' || method.toLowerCase() === 'delete') {
      delete options.body;
    }

    try {
      const response = await fetch(`${APIHOST}${endpoint}`, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      try {
        const responseData = await response.json(); // Attempt to parse JSON
        return responseData;
      } catch (error) {
        if (error.name === "SyntaxError") {
          console.warn("No JSON content returned, but request was successful.");
          return null; // Handle cases where no JSON response is expected
        }
        throw error; // Re-throw other parsing errors
      }
    } catch (error) {
      console.error(`${method} request failed at ${APIHOST}${endpoint}:`, error);
      throw new Error(`Failed to fetch: ${error.message}`);
    }
  }

  // Phương thức GET
  get(endpoint) {
    return this.request('GET', endpoint);
  }

  // Phương thức POST
  post(endpoint, data) {
    return this.request('POST', endpoint, data);
  }

  // Phương thức PUT
  put(endpoint, data) {
    return this.request('PUT', endpoint, data);
  }

  // Phương thức PATCH
  patch(endpoint) {
    return this.request('PATCH', endpoint);
  }

  // Phương thức DELETE
  delete(endpoint) {
    return this.request('DELETE', endpoint);
  }
}

export default FetchClient;

import React, { useState, useEffect } from "react"
import { Row, Col, Card, Modal, Button, Form } from "react-bootstrap"
import PageTitle from "../../components/PageTitle"
import Table from "../../components/Table"
import { Link } from "react-router-dom"
import DeleteButton from "../admin/modules/Model/components/deleted"
import RestoreButton from "../admin/modules/Model/components/restore"
import * as XLSX from 'xlsx'

interface AdvancedProps {
  columns: any[]
  data: any[]
  title: string
  label: string
  onSubmit: any
  onUpdate: any
  onDelete: any
  onRestore: any
  formRender: any
  link: string
  isAdd: boolean
  isExpand: boolean
  isSelect: boolean
  moreData: any
  isEdit: boolean
  buttonTitle: string
  fetchData: any
  isForm: boolean
  isDelete: boolean
  canDelete: boolean
  isImport: boolean
  onImport: () => void
  isAction: boolean
  isSearch: boolean
  isResetPass: boolean
  onResetPass: (id: any) => void
  isPopup: boolean
}

const Advanced: React.FC<AdvancedProps> = ({
  isForm = true,
  canDelete = true,
  isDelete = true,
  isImport = false,
  isSearch = true,
  isAction = true,
  fetchData, buttonTitle,
  onImport,
  isResetPass = false,
  onResetPass,
  isPopup = false,
  moreData, isSelect, data, columns, title, label, onSubmit, onUpdate, onDelete, onRestore, formRender, link, isAdd = true, isEdit = true, isExpand
}) => {
  const [showModal, setShowModal] = useState(false)
  const [formData, setFormData] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [showAddPopup, setShowAddPopup] = useState(false)

  useEffect(() => {
    console.log({ isResetPass }) // Check if isResetPass is being passed correctly
  }, [isResetPass])

  const handleShowModal = (dataRow?: any) => {
    setFormData(dataRow || null) // Pass null for new entries, dataRow for edits
    setIsEditMode(!!dataRow) // True if updating, false if adding
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setFormData(null)
  }

  const handleFormSubmit = async () => {
    if (isEditMode && formData) {
      await onUpdate(formData)
    } else if (formData) {
      await onSubmit(formData)
    }
    handleCloseModal()
  }

  const columnsWithActions = [
    ...columns,
    ...(isAction ? [{
      Header: 'Actions',
      accessor: 'actions',
      width: '30%',
      Cell: ({ row }: any) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {isEdit && (
            <Button variant="info" onClick={() => handleShowModal(row.original)} style={{ minWidth: "80px", borderRadius: "7px" }}>
              <i className="mdi mdi-content-save-edit-outline me-1"></i>Edit
            </Button>
          )}
          {canDelete && (
            isDelete ? (
              row.original.IsDelete === false ? (
                <DeleteButton
                  onDelete={onDelete}
                  id={row.original.Id}
                  fetchtype={fetchData}
                  title={"Delete"}
                  iconClass="mdi mdi-delete me-1"
                />
              ) : (
                <RestoreButton
                  onDelete={onDelete}
                  id={row.original.Id}
                  fetchtype={fetchData}
                  title={"Restore"}
                />
              )
            ) : (
              row.original.isBanned === false ? (
                <DeleteButton
                  onDelete={onDelete}
                  id={row.original.id}
                  fetchtype={fetchData}
                  title={"Ban"}
                  iconClass="fe-x me-1"
                />
              ) : (
                <RestoreButton
                  onDelete={onDelete}
                  id={row.original.id}
                  fetchtype={fetchData}
                  title={"UnBan"}
                />
              )
            )
          )}
          {isSelect && (
            <Button variant="info" onClick={() => moreData(row.original.id || row.original.Id)} style={{ minWidth: "120px", borderRadius: "7px" }}>
              {buttonTitle}
            </Button>
          )}
          {isResetPass && (
            <Button variant="warning" onClick={() => onResetPass(row.original.id || row.original.Id)} style={{ minWidth: "150px", borderRadius: "7px" }}>
              <i className="mdi mdi-lock-reset me-1"></i>Reset Password
            </Button>
          )}
        </div>
      ),
    }] : [])
  ]

  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "All", value: data.length },
  ]

  const exportEmptyExcelWithHeaders = () => {
    // Define headers and their properties
    const headers = [
      { header: 'Email', key: 'Email', width: 20 },
      { header: 'PhoneNumber', key: 'PhoneNumber', width: 20 },
      { header: 'LastName', key: 'LastName', width: 15 },
      { header: 'FirstName', key: 'FirstName', width: 15 },
      { header: 'DateOfBirth', key: 'DateOfBirth', width: 15 },
      { header: 'Gender', key: 'Gender', width: 10 },
    ]

    // Create a new workbook and a new worksheet
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.aoa_to_sheet([])

    // Adding headers manually to control styling as much as possible
    const headerRow = worksheet['!ref'] = 'A1:' + String.fromCharCode(65 + headers.length - 1) + '1'
    headers.forEach((header, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index })
      worksheet[cellRef] = {
        v: header.header, t: 's', s: {
          font: { bold: true },
          alignment: { horizontal: "center" }
        }
      }
    })

    // Set column widths
    worksheet['!cols'] = headers.map(header => ({ wch: header.width }))

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TemplateStudent')

    // Write the workbook to a file
    XLSX.writeFile(workbook, 'TemplateStudent.xlsx')
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: label, path: `/${label}`, active: false }]}
        title={title}
      />
      <div className="d-flex justify-content-between my-1 my-lg-0 pb-3">
        {isAdd && (
          isPopup ? (
            <Button className="btn btn-success waves-effect waves-light" onClick={() => handleShowModal()}>
              <i className="mdi mdi-plus-circle me-1"></i> Add New
            </Button>
          ) : (
            <Link to={link} className="btn btn-success waves-effect waves-light">
              <i className="mdi mdi-plus-circle me-1"></i> Add New
            </Link>
          )
        )}
        {isImport && (
          <div>
            <Button className="btn btn-success waves-effect waves-light me-2" onClick={onImport}>
              <i className="mdi mdi-plus-circle me-1"></i> Import
            </Button>
            <Button className="btn btn-info waves-effect waves-light" onClick={exportEmptyExcelWithHeaders}>
              <i className="mdi mdi-plus-circle me-1"></i> Download Import Template
            </Button>
          </div>
        )}
      </div>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columnsWithActions}
                data={data}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={isSearch}
                isExpandable={isExpand}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? 'Update Entry' : 'Add New'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            e.preventDefault()
            handleFormSubmit()
          }}>
            <Form.Group>
              {isForm && formRender({ formData, setFormData })}
            </Form.Group>

            <Button variant="danger" onClick={handleCloseModal} style={{ marginRight: '10px' }}>Close</Button>
            <Button type="submit" variant="success">{isEditMode ? 'Update' : 'Save'}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Advanced

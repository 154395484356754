import React from 'react';
import { withSwal } from 'react-sweetalert2';
import { Button } from 'react-bootstrap';
const DeleteButton = withSwal(({ swal, onDelete, id, fetchtype, title, iconClass, witdh, className }) => (
  <Button
    variant="danger"
    className={`${className ? className : ""}`}
    onClick={async () => {
      const result = await swal.fire({
        title: 'Are you sure?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes'
      });

      if (result.isConfirmed) {
        try {
          const response =  await onDelete(id);
          console.log("Data deleted successfully:", response);
          fetchtype();
          swal.fire('Successful!', '', 'success');
        } catch (error) {
          console.error("Failed to delete data:", error);
          swal.fire('Error!', 'Failed to delete data.', 'error');
        }
      }
    }}
    style={{ minWidth: witdh ? `${witdh}` : "100px", borderRadius: "7px" }}
  >
    <i className={iconClass}></i>{title}
  </Button>
));

export default DeleteButton;

import FetchClient from "../utils/axiosClient";
import { API } from "../utils/api";


class MajorServices {
    constructor() {
        this.AxiosClient = new FetchClient()
    }
    async getAll() {
        try {
            const term = await this.AxiosClient.get(`${API.MAJOR}?$expand=department`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }
    
    async getDetails(key) {
        try {
            const term = await this.AxiosClient.get(`${API.MAJOR}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async create(data) {
        try {
             await this.AxiosClient.post(`${API.MAJOR}`, data);
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async update(key, data) {
        try {
            const term = await this.AxiosClient.put(`${API.MAJOR}/${key}`, data);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

    async delete(key) {
        try {
            const term = await this.AxiosClient.delete(`${API.MAJOR}/${key}`);
            return term;
        } catch (error) {
            console.error("Failed to fetch term details:", error);
            throw error;
        }
    }

}
export default MajorServices;
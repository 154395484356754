import { useState, useEffect, useRef } from "react";
import Advanced from "../../../../tables/Advanced";
import { departmentColumns } from "../data";
import departmentServices from "../../../services/department";
import { FormRender } from "./departmentForm";
function DepartmentManagement() {
  const [departments, setdepartments] = useState([]);
  const [loading, setLoading] = useState(true);

  const departmentService = new departmentServices();


  const fetchdepartments = async () => {
    setLoading(true);
    const data = await departmentService.getAll();
    console.log("-------------------------------------log data");
    console.log(data);
    if (typeof data === "string") {
      setdepartments([]);
      console.error("Failed to fetch data:", data);

    } else {
      setdepartments(data.value);

    }
    setLoading(false);
  };



  useEffect(() => {
    fetchdepartments();
  }, []);

  const handleSubmit = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await departmentService.create(data);
    console.log("Data submitted successfully:", response);
    fetchdepartments();
  };

  const handleUpdate = async (data) => {
    console.log("Submitting data:", data.value);
    const response = await departmentService.update(data.Id, data);
    console.log("Data submitted successfully:", response);
    fetchdepartments();
  };

  const handleDelete = async (id) => {

    try {
      const response = await departmentService.delete(id);
      console.log("Data deleted successfully:", response);
      fetchdepartments();
    } catch (error) {
      console.error("Failed to delete data:", error);
    }

  };


  function formRender({ formData, setFormData } = {}) {
    return (
      <FormRender formData={formData} setFormData={setFormData} />
    )
  }


  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Advanced
          label="Department"
          title="Department MANAGEMENT"
          link="/department/create"
          isPopup={true}
          columns={departmentColumns}
          data={departments}
          formRender={formRender}
          onSubmit={handleSubmit}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fetchData={fetchdepartments}
        />
      )}
    </div>
  );
}

export default DepartmentManagement;

import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import YearServices from '../../../services/year';
import '../form.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

export function FormRender({ formData, setFormData }) {
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(""); // State to track the selected year label
  const yearService = new YearServices();

  const fetchYears = async () => {
    const data = await yearService.getAll();
    if (typeof data === 'string') {
      setYears([]);
    } else {
      setYears(data.value);
    }
  };

  useEffect(() => {
    fetchYears();
  }, []);

  useEffect(() => {
    if (formData?.YearId && years.length > 0) {
      const yearLabel = years.find(year => Number(year.Id) === Number(formData.YearId))?.Year.toString();
      if (yearLabel) {
        setSelectedYear(yearLabel);
      } else {
        console.error("Year label not found for ID:", formData.YearId);
      }
    }
  }, [formData, years]);


  const yearOptions = years.map((year) => ({
    label: year.Year.toString(),
    value: year.Id,
  }));

  const handleSelectYear = (eventKey, event) => {
    const selectedLabel = years.find(year => year.Id.toString() === eventKey)?.Year.toString();
    setSelectedYear(selectedLabel);
    setFormData({
      ...formData,
      YearId: eventKey
    });
  };

  const handleChange = (selected) => {
    const selectedValue = selected.length > 0 ? selected[0].value : '';
    setFormData({ ...formData, YearId: selectedValue });
    handleSelectYear(selectedValue);
  };


  return (
    <div className="p-fluid">
      <Form.Group className="mb-3 lg-4">
        <Form.Label htmlFor="year">Year</Form.Label>
        <Typeahead
          id="year"
          onChange={handleChange}
          options={yearOptions}
          labelKey="label"
          selected={yearOptions.filter(option => option.value === formData?.YearId)}
          placeholder="Select Year"
          clearButton
        />

      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="termCode">Term Code</Form.Label>
        <Form.Control
          type="text"
          id="termCode"
          value={formData?.TermCode || ""}
          onChange={e => setFormData({ ...formData, TermCode: e.target.value })}
          placeholder="Term Code"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="termName">Term Name</Form.Label>
        <Form.Control
          type="text"
          id="termName"
          value={formData?.TermName || ""}
          onChange={e => setFormData({ ...formData, TermName: e.target.value })}
          placeholder="Term Name"
        />
      </Form.Group>
    </div>
  );
}
import { useState, useEffect } from "react";
import Advanced from "../../../../tables/Advanced";
import { accountColumns, profileColumns, profileLecturerColumns } from "../data";
import 'react-toastify/dist/ReactToastify.css';
import '../form.css';
import AccountService from "../../../services/account";
import ProfileServices from "../../../services/profile";
import FormAccountLecture from "./formLecturer";
import { withSwal } from 'react-sweetalert2';
const LectureAccount = withSwal(({ swal }) => {

    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState([]);
    const [selectedAccountId, setselectedAccountId] = useState(null);
    const [profile, setProfile] = useState(null);

    const api = new AccountService();
    const apiProfile = new ProfileServices();
    const fetchData = async () => {
        setLoading(true);

        const data = await api.getAllAccountLecture();
        console.log("get all account lecture", data)
        setAccount(data.result);
        setLoading(false);
    };


    const handleData = async (userId) => {
        try {
            const answersData = await apiProfile.getId(userId);
            console.log("get profile", answersData);
            
            setProfile([answersData.result]);
            setselectedAccountId(userId);
            console.log(answersData);
        } catch (error) {
            console.error("Failed to fetch answers:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdate = async (data) => {
        console.log("Submitting data:", data);
        console.log("Data submitted successfully:");
        const updateResponse = await fetch('https://crud.live:446/api/profile-management', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        console.log("Data updated successfully:", updateResponse);
        fetchData();
    };
    function formRender({ formData, setFormData } = {}) {
        return (
            <FormAccountLecture formData={formData} setFormData={setFormData} />
        )
    }

    
 const handleResetPass = async (id) => {
    swal.fire({
        title: 'Reset Password?',
        text: "This will reset the password for the account.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#66bb6a', 
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            const profile = await apiProfile.getId(id);
            const response = await fetch('https://crud.live:446/api/authentication/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: profile.email })
            });
            console.log("Resetting password for account:", id);
            swal.fire(
                'Reset!',
                'The password has been reset successfully.',
                'success'
            );
        }
    });
};



    const handleDelete = async (id) => {
        try {
            const answersData = await api.getDetails(id);
            let response;
            console.log("Account unbanned successfully:", answersData.result);
            if (!answersData.result.isBanned) {
                response = await api.ban(id);
                console.log("Account banned successfully:", id);
            } else {
                response = await api.unBan(id);
                fetchData();
                console.log("Account unbanned successfully:", id);
            }

        } catch (error) {
            console.error("Error handling the ban/unban operation:", error);
        }
    };
    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Advanced
                        label="Lecturer"
                        title="Lecture Account Management"
                        columns={accountColumns}
                        data={account}
                        isSelect={true}
                        isEdit={false}
                        link="/add-lecture-account"
                        isForm={false}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        moreData={handleData}
                        fetchData={fetchData}
                        isDelete={false}
                        buttonTitle="Lecture Profile"
                        isResetPass={true}
                        onResetPass={handleResetPass}
                    />
                    {selectedAccountId &&
                        (
                            profile.length > 0 ? (
                                <Advanced
                                    label="Lecturer"
                                    title={`Profile of: ${profile[0]?.lastName} ${profile[0]?.firstName}`}
                                    columns={profileLecturerColumns}
                                    data={profile}
                                    isAdd={false}
                                    isSearch={false}
                                    formRender={formRender}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}
                                    canDelete={false}

                                />
                            ) : (
                                <Advanced
                                    title="No profile for this account"
                                    columns={profileLecturerColumns}
                                    data={profile}
                                    isAdd={false}
                                    formRender={formRender}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}
                                />
                            )
                        )}
                </>
            )}
        </div>
    );
});

export default LectureAccount;
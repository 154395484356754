export const commonColumns = [
  {
    accessor: "CreatedDate",
    Header: "Created Date",
    width: "10%",
    Cell: ({ value }) => new Date(value).toLocaleDateString()
  },
  {
    accessor: "ModifiedDate",
    Header: "Modified Date",
    width: "10%", // Adjusted width for uniformity
    Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "No Modifications"
  },
  {
    accessor: "DeletedDate",
    Header: "Deleted Date",
    width: "10%", // Adjusted width for uniformity
    Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "Not Deleted"
  },
  {
    Header: "Deleted",
    accessor: "IsDelete",
    width: "10%", // Adjusted width for uniformity
    Cell: ({ value }) => (
      <div className={`badge btn ${value ? `btn-danger` : `btn-success`}`} style={{
        color: 'inhert',
        padding: '3px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '60px',
        fontSize: '12px',
        fontWeight: 'bold'
      }}>
        {value ? "Yes" : "No"}
      </div>
    )
  }
];

export const securityColumns = [
  { accessor: "id", Header: "ID", width: "10%" },
  { accessor: "userId", Header: "UserId", width: "10%" },
  { accessor: "device", Header: "Device", width: "15%" },
  { accessor: "loginDate", Header: "Login Date", width: "10%" },
  { accessor: "location", Header: "Location", width: "15%" },
];

export const termColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "Year.Year", Header: "Year", width: "10%" },
  { accessor: "TermName", Header: "Term Name", width: "15%" },
  { accessor: "TermCode", Header: "Term Code", width: "15%" },
  ...commonColumns,
];


export const yearsColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "Year", Header: "Year", width: "10%" },

  ...commonColumns,
];

export const departmentColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "DepartmentName", Header: "Department Name", width: "10%" },
  ...commonColumns,
];

export const majorColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "Department.DepartmentName", Header: "Department Name", width: "10%" },
  { accessor: "MajorName", Header: "Major Name", width: "10%" },
  { accessor: "MajorCode", Header: "Major Code", width: "10%" },

  ...commonColumns,
];

export const typeColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "DirectoryName", Header: "Type Name", width: "10%" },
  ...commonColumns,
];

export const categoryColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "CategoryName", Header: "Category Name", width: "10%" },
  ...commonColumns,
];

export const courseCmtColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "Term.TermCode", Header: "Term", width: "10%" },
  { accessor: "CourseMajor.Major.MajorName", Header: "Major Name", width: "10%" },
  { accessor: "CourseMajor.Course.CourseName", Header: "Course Name", width: "10%" },
  { accessor: "CourseMajor.Course.Category.CategoryName", Header: "Category Name", width: "10%" },
  ...commonColumns,
];

export const courseMajorColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "Major.MajorCode", Header: "Major Code", width: "10%" },
  { accessor: "Course.CourseCode", Header: "Course Code", width: "10%" },
  ...commonColumns,
];

export const courseColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "CourseName", Header: "Course Name", width: "10%" },
  { accessor: "CourseCode", Header: "Course Code", width: "10%" },
  { accessor: "Category.CategoryName", Header: "Category Name", width: "10%" },
  ...commonColumns,
];
export const enrollColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "ProfileName", Header: "Student Name", width: "10%" },
  { accessor: "ClassId", Header: "Class", width: "10%" },
  { accessor: "Message", Header: "Message", width: "10%" },
  {
    accessor: "IsAccept", Header: "IsAccept", width: "10%",
    Cell: ({ value }) => (
      <span style={{ color: value ? 'inherit' : 'red' }}>
        {value ? "Yes" : "No"}
      </span>
    )
  },
  ...commonColumns,
];

export const dClassColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "Class.ClassCode", Header: "Class Code", width: "10%" },
  { accessor: "DirectoryType.DirectoryName", Header: "Directory Name", width: "10%" },
  ...commonColumns,
];

export const ScoreColumns = [
  { accessor: "id", Header: "ID", width: "10%" },
  { accessor: "classId", Header: "Class Code", width: "10%" },

];


export const discussionAnswerColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "ProfileName", Header: "Student", width: "10%" },
  { accessor: "AnswerContent", Header: "Answer Content", width: "10%" },

  ...commonColumns,
];

export const discussionQuestionColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "Topic", Header: "Topic", width: "10%" },
  { accessor: "QuestionContent", Header: "Question Content", width: "10%" },

  // Include other columns from commonColumns array
  ...commonColumns,

];

export const anonymousAnswerColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  { accessor: "ProfileName", Header: "Answerer Name", width: "10%" },
  { accessor: "AnswerContent", Header: "Answer Content", width: "10%" },

  ...commonColumns,
];

export const anonymousQuestionColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  {
    accessor: "ProfileName", Header: "Sender", width: "10%",
    Cell: ({ row }) => (
      !row.values.IsAnonymous ? <span>{row.values.ProfileName}</span> : "Anonymous"
    )
  },
  { accessor: "Major.MajorCode", Header: "Major Code", width: "10%" },
  { accessor: "QuestionContent", Header: "Question Content", width: "10%" },
  ...commonColumns,
  {
    accessor: "IsAnonymous", Header: "IsAnonymous", width: "10%",
    Cell: ({ value }) => (
      <span style={{ color: value ? 'red' : 'inherit' }}>
        {value ? "Yes" : "No"}
      </span>)
  },

];


export const contentColumns = [
  { accessor: "Id", Header: "ID", width: "10%" },
  {
    accessor: "File", 
    Header: "File", 
    width: "10%",
    Cell: ({ value }) => {
      if (!value) {
        return <span>No file</span>;
      }
      return (
        <a href={value.url ? value.url : "#"} download>
          {value.fileName ? value.fileName : "Not file"}
        </a>
      );
    }
  },
  { accessor: "Topic", Header: "Topic", width: "10%" },
  { accessor: "ContentName", Header: "Name", width: "10%" },
  { accessor: "Description", Header: "Description", maxWidth: "10%", Cell: ({ value }) => (
    <div style={{
      maxWidth: '80px', // adjust this width as needed
      fontSize: '14px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>
      {value}
    </div>
  ) },
  {
    accessor: "StartDate", Header: "Start Date", maxWidth: "10%",
    Cell: ({ value }) => new Date(value).toLocaleDateString()
  },
  {
    accessor: "DueDate", Header: "Due Date", width: "10%",
    Cell: ({ value }) => new Date(value).toLocaleDateString()
  },

  { accessor: "Tag", Header: "Tag", width: "10%" },
  {
    accessor: "IsMandatory", Header: "Mandatory", width: "5%",
    Cell: ({ value }) => (
      <div className={`badge btn ${value ? `btn-success` : `btn-danger`}`} style={{
        color: 'inhert',
        padding: '3px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '50px',
        fontSize: '12px',
        fontWeight: 'bold'
      }}>
        {value ? "Yes" : "No"}
      </div>
    )
  },
  {
    accessor: "IsActive", Header: "Active", width: "5%", Cell: ({ value }) => (
      <div className={`badge btn ${value ? `btn-success` : `btn-danger`}`} style={{
        color: 'inhert',
        padding: '3px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '50px',
        fontSize: '12px',
        fontWeight: 'bold'
      }}>
        {value ? "Yes" : "No"}
      </div>)
  },
  {
    Header: "Deleted",
    accessor: "IsDelete",
    width: "5%", // Adjusted width for uniformity
    Cell: ({ value }) => (
      <div className={`badge btn ${value ? `btn-danger` : `btn-success`}`} style={{
        color: 'inhert',
        padding: '3px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '50px',
        fontSize: '12px',
        fontWeight: 'bold'
      }}>
        {value ? "Yes" : "No"}
      </div>
    )
  }
];

export const accountColumns = [
  { accessor: "userName", Header: "User Name", width: "10%" },
  { accessor: "email", Header: "Email", width: "10%" },
  {
    accessor: "isBanned", Header: "Banned?", width: "10%", Cell: ({ value }) => (
      <span style={{ color: value ? 'red' : 'inherit' }}>
        {value ? "Yes" : "No"}
      </span>)
  },

];
export const profileLecturerColumns = [
  { accessor: "studentCode", Header: "Code", width: "10%" },
  {
    Header: "Full Name",
    Cell: ({ row }) => `${row.original.lastName} ${row.original.firstName} `,
    width: "20%"
  },
  { accessor: "phone", Header: "Phone", width: "10%" },
  { accessor: "secondEmail", Header: "Second Email", width: "10%" },
  { accessor: "dateOfBirth", Header: "Date Of Birth", width: "10%" },
  { accessor: "gender", Header: "Gender", width: "10%" },
  { accessor: "institution", Header: "Institution", width: "10%" },
  { accessor: "street", Header: "Street", width: "10%" },
  { accessor: "zipCode", Header: "Zip Code", width: "10%" },

];

export const profileColumns = [

  { accessor: "studentCode", Header: "StudentCode", width: "10%" },
  {
    accessor: "avatar",
    Header: "Avatar",
    width: "10%",
    Cell: ({ cell: { value } }) => <img src={value} alt="avatar" style={{ width: '50px', height: '50px', borderRadius: '10%' }} />
  },
  {
    Header: "Full Name",
    Cell: ({ row }) => `${row.original.lastName} ${row.original.firstName} `,
    width: "20%"
  },
  { accessor: "phone", Header: "Phone", width: "10%" },
  { accessor: "secondEmail", Header: "Second Email", width: "10%" },
  { accessor: "dateOfBirth", Header: "Date Of Birth", width: "10%" },
  { accessor: "gender", Header: "Gender", width: "10%" },
  { accessor: "institution", Header: "Institution", width: "10%" },
  { accessor: "street", Header: "Street", width: "10%" },
  { accessor: "country", Header: "Country", width: "10%" },

  { accessor: "zipCode", Header: "Zip Code", width: "10%" },

];